import ReactTooltip from 'react-simple-tooltip';
import { BaseComponentProps } from '@/utils/BaseComponentProps';
import { TooltipPlacement } from './TooltipTypes';
import { Trans } from 'react-i18next';

interface ITooltip extends BaseComponentProps {
	content: string;
	children?: React.ReactNode;
	placement?: TooltipPlacement;
	classes?: string;
	background?: string;
	border?: string;
	color?: string;
	minWidth?: string;
}

function Tooltip(props: ITooltip) {
	const { placement = TooltipPlacement.Top } = props;
	return (
		<div className={`${props.classes} !text-left flex items-center`}>
			<ReactTooltip
				arrow={10}
				background={'#F9F9FF'}
				border={'#E1D1FC'}
				color={'#060010'}
				content={
					<div
						style={{
							// boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
							// padding: '8px',
							// backgroundColor: '#F9F9FF',
							// borderRadius: '8px',
							minWidth: props?.minWidth,
						}}
					>
						<Trans
							i18nKey={props.content}
							components={{
								br: <br />,
							}}
						/>
					</div>
				}
				fixed={false}
				fontSize="12px"
				padding={8}
				placement={placement}
				radius={4}
			>
				{props.children}
			</ReactTooltip>
		</div>
	);
}

export default Tooltip;

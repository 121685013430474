import React from 'react'
import { RowStates } from '@/js/core/enums/RowStates'
import { __ } from '@/utils/Trans'
import { Link, useNavigate, useParams } from 'react-router-dom'
import adminStore from '../store/AdminStore'
import Badge from '@/js/components/Badge/Badge'
import TextBox from '@/js/components/TextBox/TextBox'
import { PageRoutes } from '@/utils/PageRoutes'
import BaseTable from '@/js/components/BaseTable/BaseTable'

export const BlokCompaniesStates = { ...RowStates, All: 'all' } as const

const AdminBlokCompaniesListPage = () => {
   adminStore.initializeStore()

   const navigate = useNavigate()
   const params = useParams<{ state: string }>()

   const [searchQuery, setSearchQuery] = React.useState('')
   const [selectedTab, setSelectedTab] = React.useState(params.state)

   const columns = [
      { name: __("AdminBlokCompaniesListPage.CompanyName") },
      { name: __("AdminBlokTalentListPage.Email") },
      { name: __("AdminBlokTalentListPage.BlokName") },
      { name: __("AdminBlokTalentListPage.Status") },
      { name: __("AdminBlokTalentListPage.ApplicationDate") },
      { name: __("AdminBlokTalentListPage.Acceptence/RejectionDate") },
      { name: __("AdminBlokTalentListPage.CompanyId") },
   ]

   const badges = [
      { name: __('AdminListPage.All'), type: BlokCompaniesStates.All },
      { name: __('AdminListPage.Approved'), type: BlokCompaniesStates.Approved },
      { name: __('AdminListPage.Pending'), type: BlokCompaniesStates.Pending },
      { name: __("AdminListPage.Declined"), type: BlokCompaniesStates.Declined },
   ]

   React.useEffect(() => {
      setSelectedTab(params.state)
      adminStore.fetchBlokCompanies(params.state)
   }, [params])

   return (
      <div className={"ml-8 mt-5 bg-gray-900"}>
         <h1 className={"text-3xl font-semibold text-white"}>
            {__("AdminBlokCompaniesListPage.Title")}
         </h1>
         {adminStore.state.isLoading ? null : (
            <>
               <div className={"flex gap-8 mt-8 md:flex-row flex-col"}>
                  <div className={"flex items-center flex-wrap gap-4"}>
                     {badges.map((badge, index) => (
                        <Badge
                           isClickable
                           key={index}
                           type={adminStore.getTabType(badge.type, selectedTab)}
                           onClick={() => navigate(PageRoutes.AdminBlokCompaniesListPage.replace(':state', badge.type))}
                        >
                           {badge.name}
                        </Badge>
                     ))}
                  </div>
                  <div>
                     <TextBox name={"title"} classes={"w-96"}>
                        <TextBox.Input
                           name={"search"}
                           classes={"pl-2"}
                           attr={{
                              name: "search",
                              value: searchQuery,
                              onChange: e => setSearchQuery(e.target.value),
                              placeholder: __('AdminUserListPage.SearchText')
                           }}
                        />
                     </TextBox>
                  </div>
               </div>

               <div>
                  <BaseTable columns={columns} classes={"max-w-screen-xl"}>
                     {
                        adminStore
                           .filterBlokCompaniesBySearch(adminStore.state.blokCompanies, searchQuery)
                           .map(blokCompany => (
                              <React.Fragment key={blokCompany.id}>
                                 <BaseTable.Row>
                                    <BaseTable.Row.Cell>
                                       <p className="text-gray-300">
                                          {blokCompany.companyTitle}
                                       </p>
                                    </BaseTable.Row.Cell>
                                    <BaseTable.Row.Cell>
                                       <p className="text-gray-300">{blokCompany.email}</p>
                                    </BaseTable.Row.Cell>
                                    <BaseTable.Row.Cell>
                                       <Link
                                          className="text-gray-300"
                                          to={PageRoutes.BlokPublicPage.replace(':id', blokCompany.blokId).replace(':tab?', '')}
                                       >
                                          {blokCompany.blokName}
                                       </Link>
                                    </BaseTable.Row.Cell>
                                    <BaseTable.Row.Cell>
                                       <Badge type={adminStore.getStatus(blokCompany.state).type}>
                                          {adminStore.getStatus(blokCompany.state).text}
                                       </Badge>
                                    </BaseTable.Row.Cell>
                                    <BaseTable.Row.Cell>
                                       <p className="text-gray-300">
                                          {adminStore.getDateByFormat(blokCompany.created)}
                                       </p>
                                    </BaseTable.Row.Cell>
                                    <BaseTable.Row.Cell>
                                       <p className="text-gray-300">
                                          {adminStore.getDateByFormat(blokCompany.updated)}
                                       </p>
                                    </BaseTable.Row.Cell>
                                    <BaseTable.Row.Cell>
                                       <p className="text-gray-300">{blokCompany.companyId}</p>
                                    </BaseTable.Row.Cell>
                                 </BaseTable.Row>
                              </React.Fragment>
                           ))
                     }
                  </BaseTable>
               </div>
            </>
         )}
      </div>
   )
}

export default AdminBlokCompaniesListPage
export enum Images {
    BlokOwner = '/assets/images/onboarding/blok-owner/blok-owner-layout.svg',
    CompanyWeAreHiring = '/assets/images/onboarding/company/company-we-are-hiring.svg',
    EmptyCompanyLogo = '/assets/images/onboarding/company/empty-profile.svg',
    EmptyProfileLogo = '/assets/images/talent/empty-profile-logo.svg',
    EmptyBlokLogo = '/assets/images/bloks/empty-blok-logo.svg',
    BlokTalentHidden = '/assets/images/bloks/blok-talent-hidden.png',
    EmployerDescription = '/assets/images/onboarding/employer-description.svg',
    BlokManagerDescription = '/assets/images/onboarding/blok-manager-description.svg',
    TalentDescription = '/assets/images/onboarding/talent-description.svg',
    CreateBlokDescription = '/assets/images/onboarding/blok-owner/create-blok-description.svg',
    ListNoticeDescription = '/assets/images/onboarding/blok-owner/list-notice-description.svg',
    Reward = '/assets/images/job/reward.png',
    DWSLogo = '/assets/images/logo/dws-logo.png',
    DWSBetaLogo = '/assets/images/logo/dws-beta-logo.png',
    Signup = '/assets/images/signup/signup-doodle.svg',
    Spinner = '/assets/icons/spinner.svg',
    Verified = '/assets/images/verification/verified.svg',
    Failed = '/assets/images/verification/failed.svg',
    Timeout = '/assets/images/verification/timeout.svg',
    NotFound = '/assets/images/not-found/not-found.png',
    Forbidden = '/assets/images/forbidden/forbidden.png',
    JobReward = '/assets/images/job/job-reward.png',
    PublishJobReward = '/assets/images/job/publish-job-reward.png',
    JobBlok = '/assets/images/job/job-blok.png',
    PublishJobBlok = '/assets/images/job/publish-job-blok.png',
    DWSLogoLetter = '/assets/images/logo/dws-logo-letter.svg',
    JobsLoading = '/assets/images/job/jobs-loading.svg',
    JobsPreviewLoading = '/assets/images/job/jobs-preview-loading.svg',
    JobFormLoading = '/assets/images/job/job-form-loading.svg',
    JobFormDetailsLoading = '/assets/images/job/job-form-details-loading.svg',
    JobsPageEmptyStateLoading = '/assets/images/job/jobs-page-empty-state-loading.svg',
    JobRewardLoading = '/assets/images/job/job-reward-loading.svg',
    JobVerification = '/assets/images/job/job-verification.png',
    JobSelectBlok = '/assets/images/job/job-select-blok.png',
    CertifiedEmblem = '/assets/images/footer/emblem.png',
    CommunitiesJumbatron = '/assets/images/landing/communities/communities-jumbatron.png',
    CommunitiesJumbatronEN = '/assets/images/landing/communities/communities-jumbatron-en.png',
    CommunitiesJumbatronMobile = '/assets/images/landing/communities/communities-jumbatron-mobile.png',
    CompaniesJumbatron = '/assets/images/landing/companies/companies-jumbatron.png',
    CompaniesJumbatronMobile = '/assets/images/landing/companies/companies-jumbatron-mobile.png',
    RewardedNotice = '/assets/images/landing/communities/rewarded-notice.png',
    RewardedNoticeEN = '/assets/images/landing/communities/rewarded-notice-en.png',
    SmartAlgorithm = '/assets/images/landing/companies/smart-algorithm.png',
    FasterPairing = '/assets/images/landing/companies/faster-pairing.png',
    CorrectCommunity = '/assets/images/landing/companies/correct-community.png',
    HowItWorksOne = '/assets/images/landing/communities/how-it-works-1.png',
    HowItWorksOneEN = '/assets/images/landing/communities/how-it-works-1-en.png',
    HowItWorksTwo = '/assets/images/landing/communities/how-it-works-2.png',
    HowItWorksTwoEN = '/assets/images/landing/communities/how-it-works-2-en.png',
    HowItWorksThree = '/assets/images/landing/communities/how-it-works-3.png',
    HowItWorksThreeEN = '/assets/images/landing/communities/how-it-works-3-en.png',
    HowItWorksFour = '/assets/images/landing/communities/how-it-works-4.png',
    HowItWorksFourEN = '/assets/images/landing/communities/how-it-works-4-en.png',
    MockCardOne = '/assets/images/landing/communities/mock-card-1.png',
    MockCardTwo = '/assets/images/landing/communities/mock-card-2.png',
    MockCardThree = '/assets/images/landing/communities/mock-card-3.png',
    MockCardFour = '/assets/images/landing/communities/mock-card-4.png',
    MockCardFive = '/assets/images/landing/communities/mock-card-5.png',
    MockCardSix = '/assets/images/landing/communities/mock-card-6.png',
    MockCardSeven = '/assets/images/landing/communities/mock-card-7.png',
    MockCardEight = '/assets/images/landing/communities/mock-card-8.png',
    MockCardNine = '/assets/images/landing/communities/mock-card-9.png',
    MockCardTen = '/assets/images/landing/communities/mock-card-10.png',
    MockCardEleven = '/assets/images/landing/communities/mock-card-11.png',
    MockCardTwelve = '/assets/images/landing/communities/mock-card-12.png',
    MockCardThirteen = '/assets/images/landing/communities/mock-card-13.png',
    MockCardFourteen = '/assets/images/landing/communities/mock-card-14.png',
    MockCardFifteen = '/assets/images/landing/communities/mock-card-15.png',
    MockCardSixteen = '/assets/images/landing/communities/mock-card-16.png',
    MockCardSeventeen = '/assets/images/landing/communities/mock-card-17.png',
    MockCardEighteen = '/assets/images/landing/communities/mock-card-18.png',
    MockCardNineteen = '/assets/images/landing/communities/mock-card-19.png',
    MockCardTwenty = '/assets/images/landing/communities/mock-card-20.png',
    MockCardTwentyOne = '/assets/images/landing/communities/mock-card-21.png',
    MockCardTwentyTwo = '/assets/images/landing/communities/mock-card-22.png',
    MockCardTwentyThree = '/assets/images/landing/communities/mock-card-23.png',
    MockCardTwentyFour = '/assets/images/landing/communities/mock-card-24.png',
    MockCardTwentyFive = '/assets/images/landing/communities/mock-card-25.png',
    MockCardTwentySix = '/assets/images/landing/communities/mock-card-26.png',
    MockCardTwentySeven = '/assets/images/landing/communities/mock-card-27.png',
    MockCardTwentyEight = '/assets/images/landing/communities/mock-card-28.png',
    MockCardTwentyNine = '/assets/images/landing/communities/mock-card-29.png',
    MockCardThirty = '/assets/images/landing/communities/mock-card-30.png',
    MockCardThirtyOne = '/assets/images/landing/communities/mock-card-31.png',
    MockCardThirtyTwo = '/assets/images/landing/communities/mock-card-32.png',
    MockCardThirtyThree = '/assets/images/landing/communities/mock-card-33.png',
    MockCardThirtyFour = '/assets/images/landing/communities/mock-card-34.png',
    MockCardThirtyFive = '/assets/images/landing/communities/mock-card-35.png',
    CompaniesDwsPlusOne = '/assets/images/landing/companies/dwsPlus-1.png',
    CompaniesDwsPlusTwo = '/assets/images/landing/companies/dwsPlus-2.png',
    CompaniesDwsPlusThree = '/assets/images/landing/companies/dwsPlus-3.png',
    CommunitiesDwsPlusOne = '/assets/images/landing/communities/dwsPlus-1.png',
    CommunitiesDwsPlusTwo = '/assets/images/landing/communities/dwsPlus-2.png',
    CommunitiesDwsPlusThree = '/assets/images/landing/communities/dwsPlus-3.png',
    BlokVerify = '/assets/images/bloks/verify.svg',
    JoinBlok = '/assets/images/job/join-blok.png',
    SuggestionSuccess = '/assets/images/job/suggestion-success.png',
    EmptyAvatar = '/assets/icons/empty-avatar.svg',
    AboutUsImage = '/assets/images/about/about-us-image.png',
    WhatIsBlok = '/assets/images/landingV2/what-is-blok.png',
    WhatIsBlokEN = '/assets/images/landingV2/what-is-blok-en.png',
    SectionThreeBG = '/assets/images/landingV2/section-three-bg.png',
    ShowCaseCardOneTR = '/assets/images/landingV2/showcase-card-1-tr.png',
    ShowCaseCardOneEN = '/assets/images/landingV2/showcase-card-1-en.png',
    ShowCaseCardTwoTR = '/assets/images/landingV2/showcase-card-2-tr.png',
    ShowCaseCardTwoEN = '/assets/images/landingV2/showcase-card-2-en.png',
    ShowCaseCardThreeTR = '/assets/images/landingV2/showcase-card-3-tr.png',
    ShowCaseCardThreeEN = '/assets/images/landingV2/showcase-card-3-en.png',
    ShowCaseCardFourTR = '/assets/images/landingV2/showcase-card-4-tr.png',
    ShowCaseCardFourEN = '/assets/images/landingV2/showcase-card-4-en.png',
    AvatarGroup = '/assets/images/landingV2/avatar-group.png',
    RocketEmoji = '/assets/images/landingV2/rocket-emoji.png',
    SuitcaseEmoji = '/assets/images/landingV2/suitcase-emoji.png',
    TestimonialBG = '/assets/images/landingV2/testimonial-bg.png',
    TestimonialBGMobile = '/assets/images/landingV2/testimonial-bg-mobile.png',
    SectionThreeImg = '/assets/images/landingV2/section-three-img.png',
    SectionThreeImgEN = '/assets/images/landingV2/section-three-img-en.png',
    ContactImage = '/assets/images/contact/contact.png',
    ContactUkImage = '/assets/images/contact/uk.png',
    ContactTrImage = '/assets/images/contact/turkey.png',
    TalentsLandingPeopleOne = '/assets/images/landingV2/talents-landing-people-1.png',
    TalentsLandingPeopleTwo = '/assets/images/landingV2/talents-landing-people-2.png',
    TalentCommunityCardOne = '/assets/images/landingV2/talent-community-card-1.png',
    TalentCommunityCardTwo = '/assets/images/landingV2/talent-community-card-2.png',
    TalentCommunityCardTwoEN = '/assets/images/landingV2/talent-community-card-2-en.png',
    TalentCommunityCardThree = '/assets/images/landingV2/talent-community-card-3.png',
    TalentCommunityCardThreeEN = '/assets/images/landingV2/talent-community-card-3-en.png',
    TalentCommunityCardFour = '/assets/images/landingV2/talent-community-card-4.png',
    TalentCommunityCardFive = '/assets/images/landingV2/talent-community-card-5.png',
    TalentCommunityCardFiveEN = '/assets/images/landingV2/talent-community-card-5-en.png',
    TalentCommunityCardFiveMobileTR = '/assets/images/landingV2/talent-community-card-5-mobile-tr.png',
    TalentCommunityCardFiveMobileEN = '/assets/images/landingV2/talent-community-card-5-mobile-en.png',
    InstagramIcon = '/assets/icons/instagram.png',
    ContactImageEn = '/assets/images/contact/contact_en.png',
    ContactImageTr = '/assets/images/contact/contact_tr.png',
    ContactLocationOne = '/assets/images/contact/location_one.svg',
    ContactLocationTwo = '/assets/images/contact/location_two.svg',
    CompanyLogoOne = '/assets/images/landingV2/companies/e-bebek.png',
    CompanyLogoTwo = '/assets/images/landingV2/companies/global-bilgi.png',
    CompanyLogoThree = '/assets/images/landingV2/companies/osevio.png',
    CompanyLogoFour = '/assets/images/landingV2/companies/bottobo-robotics.png',
    CompanyLogoFive = '/assets/images/landingV2/companies/bella-maison.png',
    CompanyLogoSix = '/assets/images/landingV2/companies/appcent.png',
    CompanyLogoSeven = '/assets/images/landingV2/companies/asset.png',
    CompanyLogoEight = '/assets/images/landingV2/companies/esinbad.png',
    CompanyLogoNine = '/assets/images/landingV2/companies/metro.png',
    CompanyLogoPrimaryOne = '/assets/images/landingV2/companies/e-bebek-primary.png',
    CompanyLogoPrimaryTwo = '/assets/images/landingV2/companies/global-bilgi-primary.png',
    CompanyLogoPrimaryThree = '/assets/images/landingV2/companies/bottobo-robotics-primary.png',
    CompanyLogoPrimaryFour = '/assets/images/landingV2/companies/bella-maison-primary.png',
    CompanyLogoPrimaryFive = '/assets/images/landingV2/companies/appcent-primary.png',
    CompanyLogoSecondaryOne = '/assets/images/landingV2/companies/e-bebek-secondary.png',
    CompanyLogoSecondaryTwo = '/assets/images/landingV2/companies/global-bilgi-secondary.png',
    CompanyLogoSecondaryThree = '/assets/images/landingV2/companies/bottobo-secondary.png',
    CompanyLogoSecondaryFour = '/assets/images/landingV2/companies/bella-maison-secondary.png',
    CompanyLogoSecondaryFive = '/assets/images/landingV2/companies/appcent-secondary.png',
    ContactEmailIcon = '/assets/images/contact/email_icon.svg',
    BlokImage1 = '/assets/images/landingV2/bloks/blokImage1.png',
    BlokImage2 = '/assets/images/landingV2/bloks/blokImage2.png',
    BlokImage3 = '/assets/images/landingV2/bloks/blokImage3.png',
    BlokImage4 = '/assets/images/landingV2/bloks/blokImage4.png',
    BlokImage5 = '/assets/images/landingV2/bloks/blokImage5.png',
    BlokImage6 = '/assets/images/landingV2/bloks/blokImage6.png',
    BlokImage7 = '/assets/images/landingV2/bloks/blokImage7.png',
    BlokImage8 = '/assets/images/landingV2/bloks/blokImage8.png',
    BlokImage9 = '/assets/images/landingV2/bloks/blokImage9.png',
    BlokImage10 = '/assets/images/landingV2/bloks/blokImage10.png',
    BlokImage11 = '/assets/images/landingV2/bloks/blokImage11.png',
    BlokImage12 = '/assets/images/landingV2/bloks/blokImage12.png',
    BlokImage13 = '/assets/images/landingV2/bloks/blokImage13.png',
    BlokImage14 = '/assets/images/landingV2/bloks/blokImage14.png',
    BlokImage15 = '/assets/images/landingV2/bloks/blokImage15.png',
    BlokImage16 = '/assets/images/landingV2/bloks/blokImage16.png',
    BlokImageIhkib = '/assets/images/landingV2/bloks/blokImageIhkib.png',
    BlokImageKeiken = '/assets/images/landingV2/bloks/blokImageKeiken.png',
    BlokImageDevnot = '/assets/images/landingV2/bloks/blokImageDevnot.png',
    BlokImageVestelVentures = '/assets/images/landingV2/bloks/blokImageVestelVentures.png',
    BlokImageArya = '/assets/images/landingV2/bloks/blokImageArya.png',
    BlokAvatar2 = '/assets/images/landingV2/avatar/avatar-zt.png',
    BlokAvatar4 = '/assets/images/landingV2/avatar/avatar-js.png',
    BlokAvatar8 = '/assets/images/landingV2/avatar/avatar-üü.png',
    BlokAvatar10 = '/assets/images/landingV2/avatar/avatar-tty.png',
    BlokAvatar11 = '/assets/images/landingV2/avatar/avatar-et.png',
    BlokAvatar12 = '/assets/images/landingV2/avatar/avatar-tyw.png',
    BlokAvatar13 = '/assets/images/landingV2/avatar/avatar-ioc.png',
    BlokAvatar14 = '/assets/images/landingV2/avatar/avatar-tt.png',
    BlokAvatarIhkib = '/assets/images/landingV2/avatar/avatar-ihkib.png',
    BlokAvatarCube = '/assets/images/landingV2/avatar/avatar-cf.png',
    BlogImage1 = '/assets/images/landingV2/blog/blogImage1.png',
    BlogImage2 = '/assets/images/landingV2/blog/blogImage2.png',
    BlogImage3 = '/assets/images/landingV2/blog/blogImage3.png',
    TestimonialCardImage1 = '/assets/images/landingV2/avatar/testimonial-card1.png',
    TestimonialCardImage2 = '/assets/images/landingV2/avatar/testimonial-card2.png',
    TestimonialCardImage3 = '/assets/images/landingV2/avatar/testimonial-card3.png',
    TestimonialCardImage4 = '/assets/images/landingV2/avatar/testimonial-card4.png',
    TestimonialCardImage5 = '/assets/images/landingV2/avatar/testimonial-card5.png',
    TestimonialCardImage6 = '/assets/images/landingV2/avatar/testimonial-card6.png',
    TestimonialCardImage7 = '/assets/images/landingV2/avatar/testimonial-card7.png',
    TestimonialCardImage8 = '/assets/images/landingV2/avatar/testimonial-card8.png',
    TestimonialCardImage9 = '/assets/images/landingV2/avatar/testimonial-card9.png',
    SavingsIcon = '/assets/images/landingV2/companies/savings-icon.png',
    ResumeIcon = '/assets/images/landingV2/companies/resume-icon.png',
    ArticlesIcon = '/assets/images/landingV2/companies/articles-icon.png',
    GroupTasksIcon = '/assets/images/landingV2/companies/group-tasks-icon.png',
    RewardedJobsImageTr = '/assets/images/landingV2/rewarded-jobs-image-tr.png',
    RewardedJobsImageMobileTr = '/assets/images/landingV2/rewarded-jobs-image-mobile-tr.png',
    RewardedJobsImageMobileEn = '/assets/images/landingV2/rewarded-jobs-image-mobile-en.png',
    RewardedJobsImageEn = '/assets/images/landingV2/rewarded-jobs-image-en.png',
    JobMatchingEn = '/assets/images/landingV2/job-matching-en.png',
    JobMatchingTr = '/assets/images/landingV2/job-matching-tr.png',
    JobMatchingBackground = '/assets/images/landingV2/job-matching-bg.png',
    JobMatchingMobileTr = '/assets/images/landingV2/job-matching-mobile-tr.png',
    JobMatchingMobileEn = '/assets/images/landingV2/job-matching-mobile-en.png',
    CompanyLogoWhiteOne = '/assets/images/landingV2/companies/e-bebek-white.png',
    CompanyLogoWhiteTwo = '/assets/images/landingV2/companies/global-bilgi-white.png',
    CompanyLogoWhiteThree = '/assets/images/landingV2/companies/bottobo-robotics-white.png',
    CompanyLogoWhiteFour = '/assets/images/landingV2/companies/bella-maison-white.png',
    CompanyLogoWhiteFive = '/assets/images/landingV2/companies/appcent-white.png',
    TalentsLandingCommentBG = '/assets/images/landingV2/talents-landing-comment-bg.png',
    TalentsLandingCommentBgEN = '/assets/images/landingV2/talents-landing-comment-bg-en.png',
    TalentCommentEmojiOne = '/assets/images/landingV2/talent-comment-emoji-1.png',
    TalentCommentEmojiTwo = '/assets/images/landingV2/talent-comment-emoji-2.png',
    WelcomePageDoodle = '/assets/images/auth/welcome-page-doodle.png',
    TalentOnboardingPreviewOne = '/assets/images/onboarding/talent/preview-1.png',
    TalentOnboardingPreviewOneEN = '/assets/images/onboarding/talent/preview-1-en.png',
    TalentOnboardingPreviewTwo = '/assets/images/onboarding/talent/preview-2.png',
    TalentOnboardingPreviewThreeTR = '/assets/images/onboarding/talent/preview-3-tr.png',
    TalentOnboardingPreviewThreeEN = '/assets/images/onboarding/talent/preview-3-en.png',
    CompanyOnboardingPreviewOne = '/assets/images/onboarding/company/preview-1.png',
    CompanyOnboardingPreviewTwoEN = '/assets/images/onboarding/company/preview-2-en.png',
    CompanyOnboardingPreviewTwoTR = '/assets/images/onboarding/company/preview-2-tr.png',
    CompanyOnboardingPreviewThreeTR = '/assets/images/onboarding/company/preview-3-tr.png',
    CompanyOnboardingPreviewThreeEN = '/assets/images/onboarding/company/preview-3-en.png',
    BlokOwnerOnbardingPreviewOneEN = '/assets/images/onboarding/blok-owner/preview-1-en.png',
    BlokOwnerOnbardingPreviewOneTR = '/assets/images/onboarding/blok-owner/preview-1-tr.png',
    BlokOwnerOnbardingPreviewTwoEN = '/assets/images/onboarding/blok-owner/preview-2-en.png',
    BlokOwnerOnbardingPreviewTwoTR = '/assets/images/onboarding/blok-owner/preview-2-tr.png',
    BlokOwnerOnbardingPreviewThreeEN = '/assets/images/onboarding/blok-owner/preview-3-en.png',
    BlokOwnerOnbardingPreviewThreeTR = '/assets/images/onboarding/blok-owner/preview-3-tr.png',
    TurkeyIcon = '/assets/images/onboarding/turkey.png',
    EnglandIcon = '/assets/images/onboarding/england.png',
    GermanyIcon = '/assets/images/onboarding/germany.png',
    QatarIcon = '/assets/images/onboarding/qatar.png',
    UnitedStatesIcon = '/assets/images/onboarding/united-states.png',
    UnitedArabEmiratesIcon = '/assets/images/onboarding/united-arab-emirates.png',
    JapanIcon = '/assets/images/onboarding/japan.png',
    ChinaIcon = '/assets/images/onboarding/china.png',
    AustraliaIcon = '/assets/images/onboarding/australia.png',
    SingaporeIcon = '/assets/images/onboarding/singapore.png',
    HongKongIcon = '/assets/images/onboarding/hong-kong.png',
    TalentOnboardingLastStepGIF = '/assets/images/onboarding/talent/talent-onboarding-last-step.gif',
    Clear = '/assets/icons/clear.svg',
    CompanyOnboardingLastStepTR = '/assets/images/onboarding/company/company-onboarding-last-step-tr.png',
    CompanyOnboardingLastStepEN = '/assets/images/onboarding/company/company-onboarding-last-step-en.png',
    BlokOwnerOnboardingLastStep = '/assets/images/onboarding/blok-owner/last-step.png',
    DefaultBlokPackageImage = '/assets/images/bloks/default-blok-package-image.png',
    BlokApprovalSent = '/assets/images/bloks/blok-approval-sent.png',
    PaymentSuccess = '/assets/images/payment/success.png',
    CompanyOnboardingBanner = '/assets/images/company/CompanyOnboardingBanner.png',
    TalentOnboardingBanner = '/assets/images/talent/TalentOnboardingBanner.png',
    DefaultPerkImage = '/assets/images/perks/default_perk.png',
    PerkNotMember = '/assets/images/perks/perks_member.png',
    PerkMemberPending = '/assets/images/perks/perks_member_pending.png',
}

import TroyIcon from "@/assets/TroyIcon";
import VisaIcon from "@/assets/VisaIcon";
import AmericanExpressIcon from "@/assets/icons/AmericanExpressIcon";
import DwsLogoFooter from "@/assets/icons/DwsLogoFooter";
import IyzicoIcon from "@/assets/icons/IyzicoIcon";
import LinkedInIconV3 from "@/assets/icons/LinkedInIconV3";
import MastercardIcon from "@/assets/icons/MastercardIcon";
import Button from "@/js/components/Buttons/Button";
import Icon from "@/js/components/Icon/Icon";
import Image from "@/js/components/Image/Image";
import TextBox from "@/js/components/TextBox/TextBox";
import { SocialLinks, UIState } from "@/utils/Enums";
import { Icons } from "@/utils/Icons";
import { Images } from "@/utils/Images";
import { PageRoutes } from "@/utils/PageRoutes";
import { __ } from "@/utils/Trans";
import { Link } from "react-router-dom";
import { useContract } from "@/js/modules/contracts/hooks/useContract";
import { ContractRoutes } from "@/js/modules/contracts/services/ContractRoutes";
import { Trans } from "react-i18next";
import React from 'react'
import axios from 'axios'
import { LandingEndPoints } from "../services/EndPoints";
import { errorHandler } from "@/js/helpers/errorHandler";
import { InputType } from "@/js/components/Input/InputType";

const linksColOne = [
	{ to: PageRoutes.LandingForCommunities, translationKey: 'LandingPage.Footer.ForCommunities' },
	{ to: PageRoutes.LandingPageForTalents, translationKey: 'LandingPage.Footer.ForTalents' },
	{ to: PageRoutes.LandingForCompanies, translationKey: 'LandingPage.Footer.ForCompanies' },

]

const linksColTwo = [
	{ to: PageRoutes.AboutUs, translationKey: "LandingPage.Footer.AboutUs" },
	{ to: PageRoutes.DWSPlus, translationKey: 'LandingPage.Footer.DWS+' },
	{ to: PageRoutes.Contact, translationKey: 'LandingPage.Footer.ContactPage' },
	{ to: PageRoutes.FAQ, translationKey: 'LandingPage.Footer.FAQ' },
]

const paymentIcons = [
	{ icon: <IyzicoIcon />, alt: 'Iyzico' },
	{ icon: <MastercardIcon />, alt: 'Mastercard' },
	{ icon: <VisaIcon />, alt: 'Visa' },
	{ icon: <AmericanExpressIcon />, alt: 'American Express' },
	{ icon: <TroyIcon />, alt: 'Troy' },
]

const socialIcons = [
	{ icon: <LinkedInIconV3 />, to: SocialLinks.LinkedIn },
	{ icon: <Icon name={Icons.Instagram} />, to: SocialLinks.Instagram },
	// { icon: <TwitterIconV2 />, to: SocialLinks.Instagram },
]

export default function LandingFooter() {
	return (
		<footer className={"w-full lg:px-16 lg:pb-20"}>
			<div className={"bg-shade-background px-6 pt-10 pb-4 w-full flex flex-col lg:px-10 gap-10 lg:rounded-48"}>
				<div className={"flex flex-col items-start justify-between gap-6 lg:flex-row lg:gap-8"}>
					<Link to={PageRoutes.Landing} reloadDocument>
						<DwsLogoFooter />
					</Link>
					<div className={"block lg:hidden w-full"}>
						<NewsLetterSection />
					</div>
					<span className="h-[1px] bg-gainsboro w-full block lg:hidden"></span>
					<ul className={"flex flex-col gap-[10px]"}>
						{linksColOne.map(link => (
							<li key={link.to}>
								<Link to={link.to}>
									{__(link.translationKey)}
								</Link>
							</li>
						))}
					</ul>
					<ul className={"flex flex-col gap-[10px]"}>
						{linksColTwo.map(link => (
							<li key={link.to}>
								<Link to={link.to}>
									{__(link.translationKey)}
								</Link>
							</li>
						))}
					</ul>
					<div className={"block lg:hidden"}>
						<LegalContracts />
					</div>
					<span className="h-[1px] bg-gainsboro w-full block lg:hidden"></span>
					<div className={"hidden lg:block"}>
						<NewsLetterSection />
					</div>
				</div>
				<div className={"flex flex-col items-start gap-3 lg:py-6 lg:gap-2 lg:items-center lg:flex-row"}>
					<Image
						width={46}
						height={48}
						src={Images.CertifiedEmblem}
						className={"object-cover min-w-[46px]"}
					/>
					<span className={'text-neutral-500 text-body-regular-b8'}>
						{__('LandingPage.Footer.Disclaimer')}
					</span>
				</div>
				<div className={"flex flex-col-reverse items-center justify-between gap-7 lg:border-t border-lavender-web-2 lg:gap-0 lg:flex-row lg:pt-10 lg:pb-6"}>
					<div className={"hidden lg:block"}>
						<LegalContracts />
					</div>
					<div className={"flex justify-start items-center gap-6 lg:justify-center w-full lg:w-auto"}>
						{paymentIcons.map(icon => (
							<div key={icon.alt} className="inline-block">
								{icon.icon}
							</div>
						))}
					</div>
					<p className={'text-neutral-500 text-body-regular-b8 lg:text-body-regular-b6'}>
						{__('LandingPage.Footer.CopyrightDisclaimer')}
					</p>
				</div>
			</div>
		</footer>
	)
}

const LegalContracts = () => {
	const getLinkToContract = useContract()
	return (
		<div className="text-floral-lavender underline ">
			<Trans
				i18nKey={"LandingPage.Footer.LegalContracts"}
				components={{ Link: getLinkToContract(ContractRoutes.PlatformTermsOfUse) }}
			/>
		</div>
	)
}

const NewsLetterSection = () => {
	const [values, setValues] = React.useState({ email: '', error: '' })
	const [success, setSuccess] = React.useState(false)

	const handleNewsLetterSubmit = async () => {
		setSuccess(false)
		if (!values.email) return
		setValues({ ...values, error: '' })

		try {
			const response = await axios.post(LandingEndPoints.NewsLetter, {
				email: values.email
			})

			if (response.status === 201) {
				setValues({ ...values, email: '' })
				setSuccess(true)
			}

		} catch (error) {
			setValues({
				...values,
				error: errorHandler(error)!['email'] as string
			})
		}
	}

	React.useEffect(() => {
		const timer = setTimeout(() => {
			setSuccess(false)
		}, 2000)

		return () => clearTimeout(timer)
	}, [success])

	return (
		<div className={"flex flex-col-reverse lg:flex-col"}>
			<div className={"flex flex-col gap-[10px]"}>
				<span className={"text-body-regular-b4 text-shade-black"}>
					{__("LandingPage.Footer.NewsLetter")}
				</span>
				<div className={"flex flex-col items-center gap-4 xl:flex-row"}>
					<TextBox name={"newsLetter"} classes={"w-full lg:w-[261px]"}>
						<TextBox.Input
							name={"email"}
							stateType={success ? UIState.Success : UIState.Default}
							attr={{
								name: 'newsLetterEmail',
								value: values.email,
								type: InputType.Email,
								onChange: e => setValues({ ...values, email: e.target.value, error: '' }),
								maxLength: 200,
								placeholder: __("LandingPage.Footer.EnterYourEmail")
							}}
						/>
					</TextBox>
					<Button
						name={'registerBtn'}
						attrs={{ onClick: handleNewsLetterSubmit }}
						classes={"!py-3 !text-body-regular-b5 w-full xl:w-auto"}
					>
						{__("LandingPage.Footer.JoinOurNewsLetter")}
					</Button>
				</div>
			</div>
			<div className={"mb-6 flex items-center gap-[10px] lg:mt-6"}>
				{socialIcons.map((item, index) => (
					<Link
						key={index}
						to={item.to}
						target={"_blank"}
						className={"p-[9px] rounded-full bg-white border border-lavender-web-2"}
					>
						{item.icon}
					</Link>
				))}
			</div>
		</div>
	)
}

export enum SubscriptionEndpoints {
  FetchPlansBy = 'subscriptions/plans/interval/:paymentInterval',
  FetchPlanByName = 'subscriptions/plans/by/:planName',
  CreatePlanSubscription = 'subscriptions/plan-subscription',
  CancelPlanSubscription = 'subscriptions/plan-subscription/cancel',
  UpgradePlanSubscription = 'subscriptions/plan-subscription/upgrade',
  FetchPlanById = 'subscriptions/plans/:id',
  FetchPlanSubscriptionStatus = 'subscriptions/plan-subscription/status',
  FetchPlanSubscriptionHistory = 'subscriptions/plan-subscription/history',
  CanUseServiceFeature = 'subscriptions/plan-subscription-usages/can-use-feature',
	CreateDefaultPlan = 'subscriptions/plan-subscription/default-free-plan',
  FetchAllPlans= '/subscriptions/plans'
}

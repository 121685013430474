import ReactTooltip from "react-simple-tooltip"
import { BaseComponentProps } from "@/utils/BaseComponentProps";
import { TooltipPlacement } from "./TooltipTypes";
import { Trans } from "react-i18next";

interface ITooltip extends BaseComponentProps {
	content: string,
	children?: React.ReactNode,
	placement?: TooltipPlacement,
	classes?: string,
	background?: string,
	border?: string,
	color?: string
}

function Tooltip(props: ITooltip) {
	const { placement = TooltipPlacement.Top } = props;
	return (
		<div className={`${props.classes} !text-left flex items-center`}>
			<ReactTooltip
				arrow={10}
				background={props.background}
				border={props.border}
				color={props.color}

				content={<Trans
					i18nKey={props.content}
					components={{
						br: <br />,
					}}
				/>}
				fixed={false}
				fontSize="12px"
				padding={8}
				placement={placement}
				radius={4}
			>
				{props.children}
			</ReactTooltip>
		</div>
	)
}

export default Tooltip;

export interface IJobStoreState {

}

export interface Action {
  fetchSectors(): Promise<void> | void;
  fetchBenefits(): Promise<void> | void;
  fetchForSummary(): Promise<void> | void;
  fetchJobLevels(): Promise<void> | void;
  fetchDepartments(): Promise<void> | void;
  fetchLocationTypes(): Promise<void> | void;
  fetchJobTypes(): Promise<void> | void;
  fetchLocations(): Promise<void> | void;
  createJobPost(payload): Promise<void> | void;
  optionsMapper(options: { id: string, name: string }[]): any;
  setErrors(errors: { [key: string]: string; }): void
}

export enum CreateJobStepTypes {
  Blok = 'blok',
  Summary = 'summary',
  Detail = 'detail',
  Preview = 'preview',
  Reward = 'reward',
  Application = 'application',
  Verification = 'verification',
}

export enum JobRewardTypes {
  Fixed = 'fixed',
  Commission = 'commission'
}

export interface IJobPostData {
  id: string,
  description: string,
  name: string,
  created: string,
  updated: string,
  jobLevel: { name: string },
  canManageTeam: boolean,
  canSalaryVisible: boolean,
  minSalary: string,
  maxSalary: string,
  jobType: { name: string },
  rewardType: string,
  rewardCommission: number,
  company: {
    logoUrl: string,
    title: string;
    users: { firstName: string; lastName: string; email: string }[]
  },
  candidateCountOfHire: number,
  sector: { name: string, },
  department: { name: string, },
  locationType: {
    name: string,
  },
  location: {
    name: string,
    countryName: string,
  },
  benefits: { name: string }[],
  minExperience: number | null,
  maxExperience: number | null,
  state: string,
  jobSkills: {
    isRequired: boolean,
    name: string
    skillExperience: {
      id: string,
      name: string,
      languages: any[]
    }
  }[],
  jobEducationDegrees: { isRequired: boolean, name: string }[],
  jobLanguages: { isRequired: boolean, name: string, languageName: string, languageLevelName: string }[],
  isPublishedForPublic: boolean,
  bloks: {
    blokOwnerFullName: string;
    avatarUrl: string;
    isBlokOwnerVisible: boolean;
    name: string;
    id: string;
    state: string;
    applicationCount: string
    applicationDate: Date
    approvedDate: string
    declinedDate: string
    updated: string
    created: string
  }[],
  isOwner: boolean;
  applicationType: string;
  applicationValue: string;
  applyDate: string;
  approvedDate: string
  applicationDate: string
  declinedDate: string
  talents: [];
  applyCount: number;
  score: number;
  publishType: string;
  unpublishReason: string;
  feedback: string;
}

export enum FilterTypes {
  All = 'all',
  Draft = 'draft',
  Published = 'published',
  Unpublished = 'unpublished'
}

export enum JobPostPublishTypes {
  Reward = 'reward',
  Blok = 'blok',
}

export enum JobPostApplicationTypes {
  Email = 'email',
  Url = 'url'
}

export enum JobPostTypes {
  Draft = 'draft',
  Published = 'published',
  Unpublished = 'unpublished',
  Pending = 'pending',
  Rejected = 'rejected',
}

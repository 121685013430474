import BaseStore from "@/js/core/BaseStore";
import { ILocationStore } from "@/js/modules/locations/store/LocationTypes";
import SkillService from "@/js/modules/skills/services/SkillService";

const skillStore = {
  skills: [],
}

class SkillsStore extends BaseStore<ILocationStore, SkillService> {
  async fetchSkills() {
    const { data } = await this.service.fetchSkills();
    this.state.skills = data.result;
  }

  optionsMapper(): { value: string, label: string }[] {
    return this.state.skills.map((skill: any) => ({
      value: skill.id,
      label: skill.name,
    }));
  }

  addSkill(skill: any) {
    if (!this.state.skills.find(s => s.id === skill.value)) {
      this.state.skills.push({
        id: skill.value,
        name: skill.label,
        is_new: skill.__isNew__,
      });
    }
  }
}

export default new SkillsStore(skillStore, SkillService);

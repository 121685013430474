const PackagesIconFilled = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
		>
			<g clip-path="url(#clip0_8126_34766)">
				<path
					d="M1 3.59091C1 2.15999 2.15999 1 3.59091 1H6.18182C7.61274 1 8.77273 2.15999 8.77273 3.59091V6.18182C8.77273 7.61274 7.61274 8.77273 6.18182 8.77273H3.59091C2.15999 8.77273 1 7.61274 1 6.18182V3.59091Z"
					fill="currentColor"
				/>
				<path
					d="M1 14.8182C1 13.3873 2.15999 12.2273 3.59091 12.2273H6.18182C7.61274 12.2273 8.77273 13.3873 8.77273 14.8182V17.4091C8.77273 18.84 7.61274 20 6.18182 20H3.59091C2.15999 20 1 18.84 1 17.4091V14.8182Z"
					fill="currentColor"
				/>
				<path
					d="M12.2273 3.59091C12.2273 2.15999 13.3873 1 14.8182 1H17.4091C18.84 1 20 2.15999 20 3.59091V6.18182C20 7.61274 18.84 8.77273 17.4091 8.77273H14.8182C13.3873 8.77273 12.2273 7.61274 12.2273 6.18182V3.59091Z"
					fill="currentColor"
				/>
				<path
					d="M12.2273 14.8182C12.2273 13.3873 13.3873 12.2273 14.8182 12.2273H17.4091C18.84 12.2273 20 13.3873 20 14.8182V17.4091C20 18.84 18.84 20 17.4091 20H14.8182C13.3873 20 12.2273 18.84 12.2273 17.4091V14.8182Z"
					fill="currentColor"
				/>
				<path
					d="M1 3.59091C1 2.15999 2.15999 1 3.59091 1H6.18182C7.61274 1 8.77273 2.15999 8.77273 3.59091V6.18182C8.77273 7.61274 7.61274 8.77273 6.18182 8.77273H3.59091C2.15999 8.77273 1 7.61274 1 6.18182V3.59091Z"
					stroke="currentColor"
					strokeWidth="1.72727"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M1 14.8182C1 13.3873 2.15999 12.2273 3.59091 12.2273H6.18182C7.61274 12.2273 8.77273 13.3873 8.77273 14.8182V17.4091C8.77273 18.84 7.61274 20 6.18182 20H3.59091C2.15999 20 1 18.84 1 17.4091V14.8182Z"
					stroke="currentColor"
					strokeWidth="1.72727"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M12.2273 3.59091C12.2273 2.15999 13.3873 1 14.8182 1H17.4091C18.84 1 20 2.15999 20 3.59091V6.18182C20 7.61274 18.84 8.77273 17.4091 8.77273H14.8182C13.3873 8.77273 12.2273 7.61274 12.2273 6.18182V3.59091Z"
					stroke="currentColor"
					strokeWidth="1.72727"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M12.2273 14.8182C12.2273 13.3873 13.3873 12.2273 14.8182 12.2273H17.4091C18.84 12.2273 20 13.3873 20 14.8182V17.4091C20 18.84 18.84 20 17.4091 20H14.8182C13.3873 20 12.2273 18.84 12.2273 17.4091V14.8182Z"
					stroke="currentColor"
					strokeWidth="1.72727"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_8126_34766">
					<rect width="21" height="21" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default PackagesIconFilled;

export enum BlokEndpoints {
  FetchBlokOwner = '/users/blok-owner',
  FetchBlokByAuthUser = '/blok-users',
  FetchBlok = '/bloks/:id',
	FetchBlokForServicePage = '/bloks/:id/services',
  FetchBloks = '/bloks',
	FetchJoinedBloks = '/bloks/joined',
	FetchFeaturedBloks = '/bloks/featured',
  FetchJoinedBloksByUserId = 'bloks/:talentId/joined',
	FetchJoinedAndPendingBloksByUserId = 'bloks/:talentId/approved-pending',
	FetchAllBloks = '/bloks/all',
  FetchBloksForAdmin = '/bloks/for/admin',
  FetchBlokPackages = '/packages/by/:ownerType/:ownerId',
  FetchBlokAnalytics = '/bloks/:id/statistics',
  FetchBlokTalents = '/bloks/:id/talents',
	FetchBlokTalentsForNonMembers = '/bloks/:id/featured-members',
  FetchBlokCompanies = '/bloks/:id/companies',
  FetchBlokJobPosts = '/bloks/:id/job-posts',
  FetchBloksByCompany = '/bloks/by/company/:id',
  FetchBloksByJobPost = '/bloks/by/jobPost/:id',
  UpdateBlokJobPostState = '/bloks/:id/job-posts/:jobPostId',
  UpdateBlokTalentState = '/bloks/:id/talents/:talentId',
  UpdateBlokCompanyState = '/bloks/:id/companies/:companyId',
  CreateBlok = '/bloks',
  UpdateBlok = '/bloks/:id',
  CheckTalentIsExist = '/bloks/check-talents',
  UploadCoverPhoto = '/bloks/:id/updateCover',
  UploadAvatarPhoto = '/bloks/:id/updateAvatar',
  Purchase = '/payments/start',
  SendInvite = '/bloks/invites',
	FetchBlokInfo = '/bloks/:id/info',
  FetchBlokAllPosts = '/bloks/posts/:ownerId',
  FetchBlokState = '/bloks/state',
  FetchBlokPost = '/bloks/posts/:ownerId/:id',
	FetchAllBlokPosts = '/bloks/post/all',
  BlokTalentCount = '/bloks/:id/talent-count',
  BlokCompanyCount = '/bloks/:id/company-count',
}

export enum TalentEndpoints {
	CreateTalent = '/talents',
	UpdateTalent = '/talents/:id',
	FetchTalent = '/talents/:id',
	UploadAvatarPhoto = '/talents/:id/updateAvatar',
	UploadCoverPhoto = '/talents/:id/updateCover',
	UpdateWorkPreferences = '/talents/updateWorkPreferences/:id',
	FetchTalentByAuth = '/talents/by/auth',
	createForEvent = '/talents/for-event',
	UpdateImportedCompany = '/talents/updateImportedTalent',
	FetchTalentForVisitorPage = '/talents/:id/visitor',
}

import BaseStore from '@/js/core/BaseStore';
import BlokService from '../services/BlokService';
import { IBlokStore } from '@/js/modules/bloks/store/BlokTypes';
import socialAccountStore from '@/js/modules/socialMedias/store/SocialAccountStore';
import blokOwnerStore from '@/js/modules/bloks/store/BlokOwnerStore';
import sectorStore from '@/js/modules/sectors/store/SectorStore';
import skillStore from '@/js/modules/skills/store/SkillStore';
import locationStore from '@/js/modules/locations/store/LocationStore';
import { errorHandler } from '@/js/helpers/errorHandler';
import { RowStates } from '@/js/core/enums/RowStates';
import { __ } from '@/utils/Trans';
import { AccountTypes, BlokNetworkReach, PackageSlugs, ServiceCategory, UIState } from '@/utils/Enums';
import userStore from '@/js/modules/users/store/v2/UserStore';
import blokTabStore from '@/js/modules/bloks/store/BlokTabStore';
import talentProfileStore from '@/js/modules/talent/store/TalentProfileStore';
import companyStore from '@/js/modules/company/store/CompanyStore';
import { PaymentOwnerTypes } from '@/js/core/enums/PaymentOwnerTypes';
import TalentServiceIcon from '@/assets/icons/TalentServiceIcon';
import JobServiceIcon from '@/assets/icons/JobServiceIcon';
import InterviewServiceIcon from '@/assets/icons/InterviewServiceIcon';
import OtherServiceIcon from '@/assets/icons/OtherServiceIcon';
import AllNoticesPackageIcon from '@/assets/icons/package/AllNoticesPackageIcon';
import regionStore from '../../regions/store/RegionStore';
import LocalStorage from '@/js/helpers/localStorage';
import expertiseStore from '../../expertises/store/ExpertiseStore';
import { Tabs } from '../pages/BloksPage';

const blokStore = {
	form: {
		name: '',
		description: '',
		avatarUrl: '',
		isBlokOwnerVisible: true,
		isGlobal: false,
		locations: [],
		sectors: [],
		skills: [],
		state: '',
		socialAccounts: [],
		regions: [],
		interests: [],
		coverPhotoUrl: '',
		outreach: '',
		expertises: [],
		hasAgreement: false,
	},
	bloks: [],
	joinedAndPendingBloks: [],
	joinedAndPendingBloksFetched: false,
	packages: [],
	isInviting: false,
};

class BlokStore extends BaseStore<IBlokStore, BlokService> {
	initializeStore(state?) {
		return this.useStore(state ? state : undefined);
	}

	async getAuthUserBlokState() {
		try {
			const response = await this.service.fetchBlokState();

			return response.data.result;
		} catch (error) {
			console.log(error);
		}
	}

	skills() {
		const skills = skillStore.state.skills;
		return this.optionsMapper(skills);
	}

	sectors() {
		const sectors = sectorStore.state.sectors;
		return this.optionsMapper(sectors);
	}

	regions() {
		const regions = regionStore.state.regions;
		return this.optionsMapper(regions);
	}

	expertises() {
		const expertises = expertiseStore.state.expertises;
		return this.optionsMapper(expertises);
	}

	locations() {
		let locations = locationStore.state.locations;

		locations = locations.map(option => {
			if (option.name === option.countryName) {
				return { label: option.name, value: option.id };
			}
			locations = locations.map(option => {
				if (option.name === option.countryName) {
					return { label: option.name, value: option.id };
				}

				return { label: `${option.name}, ${option.countryName}`, value: option.id };
			});
			return { label: `${option.name}, ${option.countryName}`, value: option.id };
		});

		return locations.sort(function (a, b) {
			return a.label.localeCompare(b.label, 'tr', { sensitivity: 'base' });
		});
	}

	providers() {
		return socialAccountStore.state.socialAccounts;
	}

	optionsMapper(options) {
		return options.map(option => {
			return { label: option.name, value: option.id };
		});
	}

	async fetchById(id: string) {
		this.state.isLoading = true;
		this.state.isLoading = true;
		try {

			const response = await this.service.fetchBlok(id);

			this.setFormData(response.data.result);
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isLoading = false;
		}
	}


	async fetchBlokAboutData() {
		await Promise.all([
			socialAccountStore.fetchSocialAccounts(),
			sectorStore.fetchSectors(),
			skillStore.fetchSkills(),
			locationStore.fetchLocations(),
			regionStore.fetchRegions(),
			expertiseStore.fetchExpertises(),
		]);
	}

	async fetchForBlokStep(blokId?: string) {
		this.state.isLoading = true;

		try {
			await Promise.all([
				socialAccountStore.fetchSocialAccounts(),
				sectorStore.fetchSectors(),
				skillStore.fetchSkills(),
				locationStore.fetchLocations(),
				regionStore.fetchRegions(),
				expertiseStore.fetchExpertises(),
			]);

			if (blokId) {
				const response = await this.service.fetchBlok(blokId);

				this.setFormData(response.data.result);
			}
		} catch (error) {
			this.goToNotFound();
		} finally {
			this.state.isLoading = false;
		}
	}



	async fetchBlokByAuthUser() {
		this.state.isLoading = true;
		try {
			const response = await this.service.fetchBlokByAuthUser();

			return response.data.result;
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isLoading = false;
		}
	}


	async fetchBlokPackages(id) {
		this.state.isLoading = true;
		try {
			const response = await this.service.fetchBlokPackages(AccountTypes.BlokOwner, id);

			return this.setPackages(response.data.result);
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isLoading = false;
		}
	}


	async fetchBlokAdvertPackages(id) {
		this.state.isLoading = true;
		try {
			const response = await this.service.fetchBlokPackages(AccountTypes.BlokOwner, id);

			response.data.result.map(packet => {
				response.data.result.map(packet => {

					if (packet.slug.startsWith(PackageSlugs.PublishJobPost)) {
						return packet.price;
					}
				});
			});
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isLoading = false;
		}

	}


	setPackages(result) {
		this.state.packages = result.map(packet => {
			return {
				...packet,
				icon: packet.slug.startsWith(PackageSlugs.PublishJobPost) ? AllNoticesPackageIcon : this.getCategory(packet.category),
			};
		});

		return this.state.packages;
	}

	getCategory(category) {
		switch (category) {
			case ServiceCategory.ForTalents:
				return TalentServiceIcon;
			case ServiceCategory.ForJobPosts:
				return JobServiceIcon;
			case ServiceCategory.ForInterviews:
				return InterviewServiceIcon;
			case ServiceCategory.ForOther:
				return OtherServiceIcon;
			default:
				return null;
		}
	}

	async create(saveAsDraft = false) {
		this.state.isSaving = true;

		let state: RowStates;

		if (this.state.form.state === RowStates.Approved) {
			state = RowStates.Approved;
		} else {
			state = saveAsDraft ? RowStates.Draft : RowStates.Pending;
		}

		try {
			const payload = LocalStorage.get('onboardingPayload') as Record<string, unknown>;

			const response = await this.service.create({ ...payload, state });

			// const blokUser = await this.fetchBlokByAuthUser();
			// await packageStore.createDefaultPackage(blokUser?.blokId)

			return response;
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	async update(id: string, saveAsDraft = false) {
		this.state.isSaving = true;

		let state;

		if (this.state.form.state === RowStates.Approved) {
			state = RowStates.Approved;
		} else {
			state = saveAsDraft ? RowStates.Draft : RowStates.Pending;
		}

		try {
			const response = await this.service.update(id, {
				...this.getPayload(this.state.form),
				state,
			});

			return response;
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	/**
	 * Updates information about a specific blok without updating the status.
	 * @param {string} id - The identifier of the blok to be updated.
	 */
	async updateBlokAbout(id: string) {
		this.state.isSaving = true;

		const state = this.state.form.state;

		try {
			const response = await this.service.update(id, {
				...this.getPayload(this.state.form),
				state,
			});

			return response;
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}


	async fetchForBlokOwnerStep(id: string) {
		this.state.isLoading = true;
		blokOwnerStore.state.isLoading = true;

		try {
			const response = await this.service.fetchBlok(id);

			blokOwnerStore.setFormData({
				...response.data.result.user,
				ownerSocialAccounts: response.data.result.socialAccounts,
				isBlokOwnerVisible: response.data.result.isBlokOwnerVisible
			});

			this.setFormData({
				...response.data.result,
				isBlokOwnerVisible: response.data.result.isBlokOwnerVisible
			});
		} catch (error) {
			this.goToNotFound();
		} finally {
			this.state.isLoading = false;
			blokOwnerStore.state.isLoading = false;
		}
	}

	async fetchBlokInfo(id: string) {
		this.state.isLoading = true;
		blokOwnerStore.state.isLoading = true;

		try {
			return await this.service.fetchBlokInfo(id);
		} catch (error) {
			this.goToNotFound();
		} finally {
			this.state.isLoading = false;
			blokOwnerStore.state.isLoading = false;
		}
	}

	async fetchJoinedBloks() {
		try {
			this.state.isLoading = true;

			await userStore.fetchAccountType();
			const { data } = await this.service.fetchJoinedBloks();
			this.state.bloks = data.result;
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchJoinedBloksByUserId(talentId: string) {
		try {
			this.state.isLoading = true;
			await userStore.fetchAccountType();
			const { data } = await this.service.fetchJoinedBloksByUserId(talentId);
			return data.result;
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchJoinedAndPendingBloksByUserId(talentId: string) {
		try {
			this.state.isLoading = true;
			await userStore.fetchAccountType();
			const { data } = await this.service.fetchJoinedAndPendingBloksByUserId(talentId);
			this.state.joinedAndPendingBloks = data.result;
			this.state.joinedAndPendingBloksFetched = true;
			return data.result;
		} finally {
			this.state.isLoading = false;
		}
	}

	async addNewBlokToJoinedBlokList(blokId: string) {
		try {
			const newBlok = {
				id: blokId,
				name: '',
				avatarUrl: '',
				state: RowStates.Pending
			};
			this.state.joinedAndPendingBloks = [...this.state.joinedAndPendingBloks, newBlok];
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchFeaturedBloks() {
		try {
			this.state.isLoading = true;
			const { data } = await this.service.fetchFeaturedBloks();
			return data.result;
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchAllBloks(pageParam, query, filterQuery, sortBy) {
		try {
			this.state.isLoading = true;
			const { data } = await this.service.fetchAllBloks(pageParam, query, filterQuery, sortBy);
			return data.result;
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchBloksForAdmin() {
		try {
			this.state.isLoading = true;

			await userStore.fetchAccountType();
			const { data } = await this.service.fetchBloksForAdmin();
			this.state.bloks = data.result;
		} finally {
			this.state.isLoading = false;
		}
	}

	getPayload(payload) {
		return {
			...payload,
			outreach: payload.outreach || '',
			regions: payload.regions || [],
			skills: payload.skills.map(skill => ({
				value: skill.value,
				isNew: skill.__isNew__,
				label: skill.label
			})),
		};
	}

	getCreatePayload() {
		return {
			...blokOwnerStore.state.form,
			...this.getPayload(this.state.form)
		};
	}

	isCompanyOwnerAccountType() {
		return userStore.isCompanyOwnerAccountType();
	}

	isTalentAccountType() {
		return userStore.isTalentAccountType();
	}

	isBlokOwnerAccountType() {
		return userStore.isBlokOwnerAccountType();
	}

	async deleteBlokTalent(id: string) {
		const user = await userStore.fetchMe();
		await talentProfileStore.fetchTalent(user.id);

		return await blokTabStore.deleteBlokTalent(id, talentProfileStore.state.talent.id);
	}

	async deleteBlokCompany(id: string) {
		const company = await companyStore.fetchCompany();

		return await blokTabStore.deleteBlokCompany(id, company.id);
	}

	async purchase(blokId: string, packageId: string) {

		try {
			this.state.isLoading = true;

			const company = await companyStore.fetchCompany();
			const result = await this.service.purchase({ packageId, ownerId: blokId, ownerType: PaymentOwnerTypes.Blok, companyId: company.id });

			return result.data.result;
		} finally {
			this.state.isLoading = false;
		}

	}

	getFilteredBloksBy(type) {
		if (type === Tabs.Invites) {
			return this.isTalentAccountType() ? this.state.bloks.filter(blok => blok.hasInvite) : [];
		}

		return this.state.bloks.filter(blok => type ? blok.applicationType === type : true);
	}

	getStatus(state) {
		if (state === RowStates.Approved) {
			return { text: __('BloksPage.Approved'), type: UIState.Success };
		} else if (state === RowStates.Pending) {
			return { text: __('BloksPage.Pending'), type: UIState.Warning };
		} else if (state === RowStates.Declined) {
			return { text: __('BloksPage.Declined'), type: UIState.Danger };
		}

		return { text: '', type: UIState.Default };
	}

	getSkillAndSectors(blok) {
		let result = [];

		result = result.concat(blok.skills);
		result = result.concat(blok.sectors);

		return result.slice(0, 3);
	}

	isApprovedState() {
		return this.state.form.state === RowStates.Approved;
	}

	setFormData(data) {
		this.state.form = {
			name: data.name,
			description: data.description,
			avatarUrl: data.avatarUrl,
			isBlokOwnerVisible: data.isBlokOwnerVisible,
			isGlobal: data.isGlobal,
			locations: data.locations,
			sectors: data.sectors,
			skills: data.skills,
			state: data.state,
			socialAccounts: data.socialAccounts,
			hasAgreement: data?.hasAgreement,
			regions: data?.regions,
			outreach: data?.outreach,
			expertises: data?.expertises,
			gsmNumber: data?.gsmNumber
		};
	}

	public resetSocialMediaErrors() {
		const errors = {};
		Object.keys(this.state.errors).map((key) => {
			if (!key.includes('url')) {
				errors[key] = this.state.errors[key];
			}
		});

		this.state.errors = errors;
	}

	async sendInvite(data) {
		try {
			this.state.isInviting = true;

			const result = await this.service.sendInvite(data);

			return result.data;
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isInviting = false;
		}
	}

	getCommunitySize(mapForSelectBox = false) {
		return [
			{ value: BlokNetworkReach.OneK, label: '1k' },
			{ value: BlokNetworkReach.OneToFiveK, label: '1k-5k' },
			{ value: BlokNetworkReach.FiveToTenK, label: '5k-10k' },
			{ value: BlokNetworkReach.TenToFiftyK, label: '10k-50k' },
			{ value: BlokNetworkReach.FiftyToFiveHundredK, label: '50k-500k' },
			{ value: BlokNetworkReach.FiveHundredToOneM, label: '500k-1m' },
			{ value: BlokNetworkReach.OneMPlus, label: '1m+' }
		]

	}

	updateAvatarImage(newAvatarUrl: string) {
		this.state.form.avatarUrl = newAvatarUrl
	}

}

export default new BlokStore(blokStore, BlokService) as BlokStore;

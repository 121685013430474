import { useIsomorphicLayoutEffect } from "@/js/hooks/useIsomorphicLayoutEffect/useIsomorphicLayoutEffect"
import { useScrollPosition } from "@/js/hooks/useScrollPosition/useScrollPosition"
import { UIState } from "@/utils/Enums"
import clsx from "clsx"
import React from 'react'

interface ILineNotifier extends React.PropsWithChildren {
	state: UIState
	show: boolean
	isSticky?: boolean
}

export default function LineNotifier(props: ILineNotifier) {
	const [stickyClasses, setStickyClasses] = React.useState('')
	const scrollPosition = useScrollPosition()

	const getBackgroundColor = () => {
		switch (props.state) {
			case UIState.Success:
				return 'bg-narvik-green';
			case UIState.Error:
				return 'bg-error-50';
			default:
				break;
		}
	}

	useIsomorphicLayoutEffect(() => {
		if (props.isSticky) {
			if (scrollPosition !== 0) {
				setStickyClasses('shadow fixed inset-x-0 top-0 z-[99999]');
			} else {
				setStickyClasses('');
			}
		}
	}, [scrollPosition])

	return !props.show
		? <React.Fragment />
		: (
			<div
				className={
					clsx(
						`flex items-center justify-center min-h-[75px] w-full p-3 ${getBackgroundColor()} xl:px-0 xl:py-6`,
						{ [stickyClasses]: props.isSticky }
					)
				}
			>
				{props.children}
			</div>
		)
}

import TextArea from '@/js/components/TextArea/TextArea';
import TextBox from '@/js/components/TextBox/TextBox';
import { Outlet } from 'react-router-dom';
import Button from '@/js/components/Buttons/Button';
import { InputType } from '@/js/components/Input/InputType';
import { __ } from '@/utils/Trans';
import axios from 'axios';
import React, { useState } from 'react';
import { AccountTypes, LandingSubmitTypes, SupportMail } from '@/utils/Enums';
import { useLocation } from 'react-router-dom';
import { LandingEndPoints } from '@/js/modules/landing/services/EndPoints';
import { PageRoutes } from '@/utils/PageRoutes';
import { errorHandler } from '@/js/helpers/errorHandler';
import { toast } from 'react-hot-toast';
import CookieConsentBanner from '@/js/modules/landing/components/CookieConsentBanner';
import LandingFooter from '@/js/modules/landing/components/LandingFooter';
import PublicNavbar from '@/js/modules/landing/components/PublicNavbar';
import { Images } from '@/utils/Images';
import Image from '@/js/components/Image/Image';
import { PhoneInput } from 'react-international-phone';

const initialValues = {
  fullName: '',
  accountType: '',
  email: '',
  phoneNumber: '',
  message: '',
  firstname: '',
  surname: '',
};

const ContactLayout = () => {
  const [values, setValues] = React.useState(initialValues);
  const [errors, setErrors] = React.useState({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [accountType, setAccountType] = useState<string | null>(null);
  const [phone, setPhoneNumber] = useState<string | null>(null);

  const handleAccountTypeClick = (accountId: string) => {
    setAccountType(accountId === accountType ? null : accountId);
    setValues({ ...values, accountType: accountId });
  };
  const handlePhoneInput = (PhoneInput: string) => {
    setPhoneNumber(phone === phone ? null : PhoneInput);
    setValues({ ...values, phoneNumber: PhoneInput });
  };
  const location = useLocation();

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    values.fullName = values.firstname + values.surname;
    setIsSubmitting(true);
    setErrors({});

    const payload = {
      ...values,
      type:
        location.pathname === PageRoutes.RequestDemo
          ? LandingSubmitTypes.Demo
          : LandingSubmitTypes.Question,
    };

    try {
      const response = await axios.post(LandingEndPoints.Contacts, payload);

      if (response.status === 201) {
        toast.success(__('ContactLayout.SuccessMessage'));
      }
    } catch (error) {
      setErrors(errorHandler(error)!);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;

    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
      return;
    }

    if (name === 'firstname' || name === 'surname') {
      initialValues.fullName = initialValues.firstname + initialValues.surname;
    }
    setValues({ ...values, [name]: value });
  };

  return (
    <>
      <CookieConsentBanner />
      <PublicNavbar />
      <form
        className={
          'flex flex-col lg:flex-row lg3:flex-row sm:flex-col-reverse xxs:flex-col-reverse lg:px-[4rem] lg:pb-20 '
        }
        onSubmit={handleFormSubmit}
      >
        <div
          className={
            'flex-1 px-8 lg:pr-12 lg:pl-12 pt-8 lg:pt-24 lg3:pr-12 lg3:pl-12 pt-8 pb-8 flex flex-col gap-6 sm:col-span-4 bg-gray-50'
          }
        >
          <h1
            className={
              'text-primary-700 leading-10 text-3xl font-extrabold xxs:hidden lg3:hidden'
            }
          >
            {__('LandingCommon.ContactPage.DoYouHaveAnyQuestion')}
          </h1>
          <span
            className={
              'leading-6 text-blue-gray-500 text-lg xxs:hidden lg3:hidden'
            }
          >
            {__('LandingCommon.ContactPage.ContactUs')}
          </span>

          <div className={'flex flex-col gap-8 sm:gap-5 sm:flex-row'}>
            <div className={'w-full'}>
              <TextBox name={'firstname'}>
                <TextBox.Label>
                  {__('LandingCommon.ContactLayout.Name')}
                </TextBox.Label>
                <TextBox.Input
                  name={'firstname'}
                  error={errors}
                  attr={{
                    value: values.firstname,
                    placeholder: __(
                      'LandingCommon.ContactLayout.NamePlaceholder',
                    ),
                    onChange: handleInputChange,
                    name: 'firstname',
                    type: InputType.Text,
                  }}
                />
              </TextBox>
            </div>

            <div className={'w-full'}>
              <TextBox name={'surname'}>
                <TextBox.Label>
                  {__('LandingCommon.ContactLayout.Surname')}
                </TextBox.Label>
                <TextBox.Input
                  name={'surname'}
                  error={errors}
                  attr={{
                    value: values.surname,
                    placeholder: __(
                      'LandingCommon.ContactLayout.SurnamePlaceholder',
                    ),
                    onChange: handleInputChange,
                    name: 'surname',
                    type: InputType.Text,
                  }}
                />
              </TextBox>
            </div>
          </div>

          <div className={'flex flex-col gap-8 sm:gap-5 sm:flex-row '}>
            <div className={'w-full'}>
              <TextBox name={'email'}>
                <TextBox.Label>
                  {__('LandingCommon.ContactLayout.Email')}
                </TextBox.Label>
                <TextBox.Input
                  name={'email'}
                  error={errors}
                  attr={{
                    value: values.email,
                    placeholder: __(
                      'LandingCommon.ContactLayout.EmailPlaceholder',
                    ),
                    onChange: handleInputChange,
                    name: 'email',
                    type: InputType.Email,
                  }}
                />
              </TextBox>
            </div>

            <div className={'w-full'}>
              <TextBox.Label>
                {__('LandingCommon.ContactLayout.PhoneNumber')}
              </TextBox.Label>
              <PhoneInput
                defaultCountry="tr"
                name={'phoneNumber'}
                placeholder={__('LandingCommon.ContactLayout.PhoneNumber')}
                value={values.phoneNumber}
                onChange={(value) => handlePhoneInput(value)}
                className={
                  'rounded-xl p-3 border-gray-50 dark:border-gray-400 dark:focus-within:border-gray-300 mt-1 flex items-center transition duration-150 ease-in-out outline-none input-wrapper bg-white PhoneInputInputContact'
                }
              />
            </div>
          </div>
          <div>
            <TextBox.Label>
              {__('LandingCommon.ContactLayout.UserType')}
            </TextBox.Label>

            <div className={'flex flex-col gap-x-2.5 sm:flex-row mt-1'}>
              <div
                className={`${accountType === AccountTypes.Talent
                  ? 'bg-[#E8DBFF] text-primary-500 border-primary-500'
                  : ''
                  } border-lavender-web-2 text-gray bg-white dark:border-gray-400 dark:focus-within:border-gray-300 mt-1 flex items-center text-base hover:text-primary-500 py-3 px-8 border hover:border-primary-500 rounded-2xl hover:bg-[#E8DBFF] rounded cursor-pointer lg:w-[200px] justify-center`}
                onClick={() => handleAccountTypeClick(AccountTypes.Talent)}
              >
                {__('AccountTypes.Talent')}
              </div>
              <div
                className={`${accountType === AccountTypes.CompanyOwner
                  ? 'bg-[#E8DBFF] text-primary-500 border-primary-500'
                  : ''
                  } border-lavender-web-2 text-gray bg-white dark:border-gray-400 dark:focus-within:border-gray-300 mt-1 flex items-center text-base hover:text-primary-500 py-3 px-8 border hover:border-primary-500 rounded-2xl hover:bg-[#E8DBFF] rounded cursor-pointer lg:w-[200px] justify-center`}
                onClick={() =>
                  handleAccountTypeClick(AccountTypes.CompanyOwner)
                }
              >
                {__('AccountTypes.CompanyOwner')}
              </div>
              <div
                className={`${accountType === AccountTypes.BlokOwner
                  ? 'bg-[#E8DBFF] text-primary-500 border-primary-500'
                  : ''
                  } border-lavender-web-2 text-gray bg-white dark:border-gray-400 dark:focus-within:border-gray-300 mt-1 flex items-center text-base hover:text-primary-500 py-3 px-8 border hover:border-primary-500 rounded-2xl hover:bg-[#E8DBFF] rounded cursor-pointer lg:w-[200px] justify-center`}
                onClick={() => handleAccountTypeClick(AccountTypes.BlokOwner)}
              >
                {__('AccountTypes.BlokOwner')}
              </div>
            </div>
          </div>

          <div className={'relative ContactTextArea'}>
            <TextBox.Label>
              {__('LandingCommon.ContactLayout.Message')}
            </TextBox.Label>
            <TextArea
              error={errors}
              attrs={{
                value: values.message,
                name: 'message',
                onChange: handleInputChange,
              }}
              name={'message'}
              placeholder={__('LandingCommon.ContactLayout.MessagePlaceholder')}
            />
          </div>

          <Button
            isLoading={isSubmitting}
            name={'submit'}
            classes={'lg:w-[240px] lg3:w-[240px]'}
          >
            {__('LandingCommon.ContactLayout.Send')}
          </Button>
        </div>

        <div
          className={
            'flex-1 bg-gray-50 lg:pl-20 lg:pr-12 lg:pt-24 lg:pb-60 lg3:pl-20 lg3:pr-12 lg3:pt-24 px-4 py-16 '
          }
        >
          <Outlet />
        </div>
      </form>

      <div
        className={
          'flex items-center w-full lg:h-[296px] lg3:h-[296px] bg-primary-500 lg:pl-20 lg:pr-40 lg3:pl-20 lg3:pr-40 lg:mb-20 '
        }
      >
        <div
          className={
            'flex xxs:flex-col xxs:text-center xxs:gap-1 gap-[16px] w-full	'
          }
        >
          <div className={'w-full flex flex-col xxs:pt-7 xxs:items-center'}>
            <h2 className={'text-white text-[24px] font-semibold mt-[-4px]'}>
              {__('LandingCommon.ContactPage.WhereUs')}
            </h2>
            <span className={'flex items-center gap-3 text-white'}>
              <Image src={Images.ContactEmailIcon} />
              {SupportMail.Support}
            </span>
          </div>
          <div className={'flex w-full xxs:flex-col xxs:text-center xxs:gap-1'}>
            <div
              className={
                'flex w-full gap-4 xxs:flex-col xxs:pt-7 xxs:text-center xxs:gap-1 xxs:items-center'
              }
            >
              <Image src={Images.ContactLocationOne} />
              <div className={'w-full mt-[-4px]'}>
                <h2 className={'text-[18px] text-white font-semibold'}>
                  {__('LandingCommon.ContactLayout.Office')}
                </h2>
                <p className={'text-[14px] text-white'}>
                  Maslak Orjin Plaza Eski <br></br>Büyükdere Cad. Maslak,
                  Sarıyer
                  <br></br> İstanbul
                </p>
              </div>
            </div>
            <div
              className={
                'flex w-full gap-4 xxs:flex-col xxs:py-7 xxs:text-center xxs:gap-1 xxs:items-center'
              }
            >
              <Image src={Images.ContactLocationTwo} />
              <div className={'w-full mt-[-4px]'}>
                <h2 className={'text-[18px] text-white font-semibold'}>
                  {__('LandingCommon.ContactLayout.TechnoOffice')}
                </h2>
                <p className={'text-[14px] text-white'}>
                  Yıldız Teknik Üniversitesi, <br></br> Davutpaşa Kampüsü,
                  Teknoloji <br></br> Geliştirme Bölgesi (TeknoPark), Esenler{' '}
                  <br></br>
                  İstanbul
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LandingFooter />
    </>
  );
};

export default ContactLayout;

import blokPublicStore from '@/js/modules/bloks/store/BlokPublicStore'
import { PageRoutes } from '@/utils/PageRoutes'
import { __ } from '@/utils/Trans'
import React from 'react'
import { NavLink, useParams } from 'react-router-dom'

interface ITopBarNavigationItem extends React.PropsWithChildren {
    item: {
        to: string,
        name: string,
        translation: string
        openInNewTab?: boolean
        reloadDocument?: boolean
        element?: React.ReactNode | JSX.Element
    }
    getClassNames: (isActive: boolean) => string,
}

const TopNavBarMenuItem = ({ item, getClassNames }: ITopBarNavigationItem) => {
    const { name, to, translation, openInNewTab = false, reloadDocument = false } = item

    const params = useParams<{ id: string }>()

    const isBlokOwnerBlokPage = blokPublicStore.state.info.isOwner && item.to === PageRoutes.MyBlokPage &&
        (window.location.pathname === PageRoutes.BlokPublicPage.replace(':id', String(params.id)).replace(':tab?', ''))

    return (
        <NavLink
            to={to}
            key={name}
            reloadDocument={reloadDocument}
            target={openInNewTab ? "_blank" : ""}
            className={({ isActive }) => `${getClassNames(isActive || isBlokOwnerBlokPage)} ${item.element ? 'flex items-center justify-center' : ''}}`}
        >
            {__(translation)}
            {item.element && item.element}
        </NavLink>
    )
}

export default TopNavBarMenuItem

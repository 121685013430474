import AddSkillWithExperienceLevelV2 from "@/js/modules/skillExperiences/components/AddSkillWithExperienceLevelV2";
import talentStore from "@/js/modules/talent/store/TalentStore";
import OnboardingBottom from "../OnboardingBottom";
import { __ } from "@/utils/Trans";
import { Trans } from "react-i18next";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import { useOutletContext } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import skillStore from "@/js/modules/skills/store/SkillStore";
import skillExperienceStore from "@/js/modules/skillExperiences/store/SkillExperienceStore";
import { toast } from "react-hot-toast";
import React from 'react'
import { useSearchParams } from 'react-router-dom';
import { isJoinBlokFlow } from "../../utils/OnboardingHelper";

export default function OnboardingTalentSkills() {
	const [skillCount, setSkillCount] = React.useState(0)
	const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>();
  const [searchParams] = useSearchParams()

	const handleOnChangeSkills = (skills: { label: string, value: string, isNew?: boolean }[]) => {
		talentStore.set("talentSkillIds", skills, {
			multiple: true,
			clearError: talentStore.getSelectBoxValues('talentSkillIds')?.length >= 2
		});
	}

	const handleContinueClick = () => {
		const newSkills = talentStore.state.form.talentSkillIds.filter(skill => skill.value && skill.skillExperience.id)

		setSkillCount(newSkills.length)
		handleOnChangeSkills(newSkills)

		if (newSkills.length > 10) {
			toast.error(__('Validations.ArrayMaxTenSize'))
			return
		}

		handleChangeStep()
	}

	React.useEffect(() => {
		setSkillCount(talentStore.state.form.talentSkillIds.filter(skill => skill.value && skill.skillExperience.id).length)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [talentStore.state.form.talentSkillIds])

	useQuery({
		queryKey: ['skills'],
		queryFn: async () => {
			await Promise.all([
				await skillStore.fetchSkills(),
				await skillExperienceStore.fetchSkillExperiences()
			])
			return true
		}
	})
	useQuery({
		queryKey: ['skills'],
		queryFn: async () => {
			await Promise.all([
				await skillStore.fetchSkills(),
				await skillExperienceStore.fetchSkillExperiences()
			])
			return true
		}
	})

	return (
		<div className="flex flex-col justify-center items-center">
			<div className={"px-4 flex flex-col justify-start items-center max-w-2xl w-full xl:gap-10 xl:p-0 xl:justify-center"}>
				<div className={`${isJoinBlokFlow(searchParams) ? 'onboarding-height' : 'onboarding-full-height'} w-full flex flex-col justify-center items-center`}>
					<div className="no-scrollbar overflow-y-auto py-10 w-full">
						<div className={"flex flex-col gap-4 w-full"}>
							<h1 className={"text-header-bold-h8 md:text-header-bold-h5 text-shade-black text-left md:text-center"}>
								{__("OnboardingPage.TalentSkills.Title")}
							</h1>
							<p className={"text-left md:text-center text-body-regular-b5 text-shade-black"}>
								<Trans
									i18nKey={"OnboardingPage.TalentSkills.Description"}
									components={{
										b: <span className={'text-primary-500 font-semibold'} />
									}}
								/>
							</p>
						</div>
						<div className={`w-full pt-7 ${skillCount >= 5 ? "max-h-[500px] overflow-y-auto" : ""}`}>
							<AddSkillWithExperienceLevelV2
								skillInputName={"talentSkillIds"}
								onChange={handleOnChangeSkills}
								errors={talentStore.state.errors}
								data={talentStore.state.form.talentSkillIds}
							/>
						</div>
					</div>
				</div>
			</div>
			<OnboardingBottom
				onClick={handleContinueClick}
				disabled={
					!(talentStore.state.form.talentSkillIds.length >= 3 &&
						(talentStore.state.form.talentSkillIds.every(skill => skill.value && skill.skillExperience.id) ||
							talentStore.state.form.talentSkillIds.filter(skill => skill.value && skill.skillExperience.id).length >= 3))
				}
			/>
		</div>
	)
}

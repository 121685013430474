import Icon from '@/js/components/Icon/Icon';
import React from 'react';
import {Icons} from '@/utils/Icons';
import {BaseComponentProps} from '@/utils/BaseComponentProps';
import {Menu, Transition} from '@headlessui/react';
import clsx from 'clsx';

interface IMoreAction extends BaseComponentProps {
    children: any;
    classes?: string;
    text?: string;
    info?: string;
    childClasses?: string;
    infoClasses?: string;
    rotate?: boolean;
}

export default function MoreAction(props: IMoreAction) {
    const {classes = '', childClasses = '', rotate = false} = props;

    const renderChild = () => {
        return React.Children.map(props.children, (child) => {
            if (child) {
                return React.cloneElement(child, {
                    className: `px-4 py-2 hover:bg-gray-50 text-gray-700 text-sm cursor-pointer flex gap-2 ${childClasses}`,
                });
            }
        });
    };

    return renderChild()?.length > 0 ? (
        <Menu as={'div'} className={'relative inline-block text-left more-action'}>
            {!!!props.text?.length && (
                <Menu.Button
                    className={clsx('flex items-center text-neutral-500 hover:text-gray-600 focus:outline-none', {
                        'rotate-90': rotate,
                    })}
                >
                    <Icon name={Icons.ThreeDot} />
                </Menu.Button>
            )}
            {!!props.text?.length && (
                <Menu.Button
                    className={'flex items-center text-gray-200 hover:text-gray-600 focus:outline-none w-full'}
                >
                    <div className="px-4 py-2 hover:bg-gray-50 text-gray-700 text-sm cursor-pointer flex gap-2 w-full">
                        {props.text}
                    </div>
                </Menu.Button>
            )}
            <Transition
                as={React.Fragment}
                enter={'transition ease-out duration-100'}
                enterFrom={'transform opacity-0 scale-95'}
                enterTo={'transform opacity-100 scale-100'}
                leave={'transition ease-in duration-75'}
                leaveFrom={'transform opacity-100 scale-100'}
                leaveTo={'transform opacity-0 scale-95'}
            >
                <Menu.Items
                    className={`absolute lg:right-0 ${classes} z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none more-action-menu`}
                >
                    <Menu.Item as={'div'} className={'sm:py-1 py-0'}>
                        <div className={'flex flex-col'}>
                            {renderChild()}
                            {!!props.info?.length && (
                                <div className={`${props.infoClasses} whitespace-break-spaces`}>{props.info}</div>
                            )}
                        </div>
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    ) : null;
}

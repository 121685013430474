import BaseStore from "@/js/core/BaseStore";
import { IResponse } from '@/js/modules/dashboard/store/UserStore.types';
import SubscriptionService from "@/js/modules/subscriptions/services/SubscriptionService.ts";
import userStore from "@/js/modules/users/store/v2/UserStore.ts";
import { SubscriptionIntervalTypes } from "@/js/modules/subscriptions/store/SubscriptionIntervalTypes.ts";
import { SubscriptionPlanNameTypes } from "@/js/modules/subscriptions/store/SubscriptionPlanNameTypes.ts";
import { PaymentStatus } from "@/js/modules/payments/store/PaymentTypes.ts";
import dayjs from "dayjs";
import { __ } from "@/utils/Trans";

const StoryStoreState = {
	plans: [],
	plan: {},
	isSubscriptionFetching: false,
	subscription: {},
	planSubscriptionUsage: {},
	history: [],
	canUseServiceFeature: false,
}

interface IState {
	plans: { id: string; name: string, title: string; price: number; }[];
	plan: { id: string; name: string, title: string; price: number; interval: SubscriptionIntervalTypes }
}

class SubscriptionStore extends BaseStore<IState, SubscriptionService> {
	initializeStore() {
		return this.useStore();
	}

	getPlanName(plan: string) {
		switch (plan) {
			case SubscriptionPlanNameTypes.Free:
				return __("SubscriptionPlan.FreePlanTitle")
			case SubscriptionPlanNameTypes.Pro:
				return 'Community Manager'
			case SubscriptionPlanNameTypes.Premium:
				return 'Enterprise'
			default:
				return ''
		}
	}

	async fetchPlanById(id) {
		try {
			this.state.isLoading = true;
			const result = await this.service.fetchPlanById(id);

			this.state.plan = result?.data?.result;
		} catch {
			this.goToNotFound();
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchPlansBy(paymentInterval: SubscriptionIntervalTypes): Promise<IResponse | any> {
		try {
			this.state.isLoading = true;
			await userStore.fetchMe();

			if (!userStore.isBlokOwnerAccountType()) {
				return userStore.goToForbidden(true);
			}

			const result = await this.service.fetchPlansBy(paymentInterval);

			this.state.plans = result?.data?.result.sort((a, b) => {
				if (a.price < b.price) return -1

				return 0;
			})

		} finally {
			this.state.isLoading = false;
		}
	}

	async createPlanSubscription(payload) {
		try {
			this.state.isSaving = true;

			return this.service.createPlanSubscription(payload);
		} finally {
			this.state.isSaving = false;
		}
	}

	async upgradePlanSubscription(payload) {
		try {
			this.state.isSaving = true;

			return this.service.upgradePlanSubscription(payload);
		} finally {
			this.state.isSaving = false;
		}
	}

	async fetchPlanSubscriptionStatus() {
		try {
			this.state.isSubscriptionFetching = true;

			const result = await this.service.fetchPlanSubscriptionStatus();
			this.state.subscription = result?.data?.result || null;
			return result?.data?.result?.plan
		} finally {
			this.state.isSubscriptionFetching = false;
		}
	}

	async CreateFreePlan() {
		try {
			this.state.isSubscriptionFetching = true;
			await this.service.CreateDefaultPlan({});
			this.fetchPlanSubscriptionStatus()
		} finally {
			this.state.isSubscriptionFetching = false;
		}
	}

	async fetchPlanSubscriptionUsage() {
		const result = await this.service.fetchPlanSubscriptionUsage();
		this.state.planSubscriptionUsage = result?.data?.result || null;
		return result?.data?.result || null;
	}

	async cancelSubscription() {
		this.state.isSaving = true;
		await this.service.cancelSubscription();

		this.state.isSaving = false;
	}

	async fetchPlanSubscriptionHistory() {
		this.state.isLoading = true;
		const result = await this.service.fetchPlanSubscriptionHistory();
		this.state.history = result?.data?.result || [];
		this.state.isLoading = false;

		return result?.data?.result || [];
	}

	async canUseServiceFeature() {
		const result = await this.service.canUseServiceFeature();
		this.state.canUseServiceFeature = result?.data?.result || false

		return this.state.canUseServiceFeature;
	}
	async fetchPlanByName(name) {
		const result = await this.service.fetchPlanByName(name);
		return result?.data?.result;
	}

	noSubscription() {
		return (!this.state.subscription) || (!this.state.subscription.status)
	}

	isFreePlan() {
		return this.state.subscription?.plan?.name === SubscriptionPlanNameTypes.Free;
	}

	isProPlan() {
		return this.state.subscription?.plan?.name === SubscriptionPlanNameTypes.Pro;
	}

	isPremiumPlan() {
		return this.state.subscription?.plan?.name === SubscriptionPlanNameTypes.Premium;
	}

	pendingPlan() {
		return this.state?.subscription?.pendingPlan;
	}

	isUnpaid() {
		return this.state?.subscription?.status === PaymentStatus.Unpaid;
	}

	isLastXDays(days) {
		const date1 = dayjs()
		const date2 = dayjs(this.state?.subscription?.endsAt)

		return date2.diff(date1, 'day') <= days;
	}
}

export default new SubscriptionStore(StoryStoreState, SubscriptionService);

import {Dialog} from '@headlessui/react';
import Button from '../Buttons/Button';
import CrossIcon from '@/assets/icons/CrossIcon';
import React, {useEffect} from 'react';
import {__} from '@/utils/Trans';
import clsx from 'clsx';

interface IModalV2 extends React.PropsWithChildren {
    modalId?: string;
    title?: string;
    open: boolean;
    zIndex?: string;
    classes?: string;
    titleClasses?: string;
    dialogTitleClasses?: string;
    onClose: (value: boolean) => void;
}

export default function ModalV2(props: IModalV2) {
    // https://github.com/tailwindlabs/headlessui/issues/762
    useEffect(() => {
        if (props.open) {
            document.documentElement.style.paddingRight = '0px';
        } else {
            document.documentElement.style.paddingRight = '0px';
        }
        // Clean up the style when the component unmounts
        return () => {
            document.documentElement.style.paddingRight = '0px';
        };
    }, [props.open]);

    return (
        <Dialog open={props.open} onClose={() => props.onClose(false)}>
            <div className="fixed inset-0 bg-black/30 z-40" aria-hidden="true" />
            <div className={'fixed inset-0 flex w-screen items-center justify-center p-4 z-50 ' + props.zIndex}>
                <Dialog.Panel
                    itemID={props.modalId || 'modal'}
                    className={clsx(
                        'w-full max-w-2xl bg-white p-[30px] rounded-20 relative max-h-[96vh] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-neutral-300 dws-modal',
                        {
                            [props.classes || '']: props.classes,
                        }
                    )}
                >
                    <Dialog.Title
                        as={'div'}
                        className={`flex items-center justify-between mb-[30px] ${props.dialogTitleClasses}`}
                    >
                        <h1
                            className={
                                props.titleClasses ? props.titleClasses : 'text-shade-black text-body-extrabold-b1'
                            }
                        >
                            {__(props.title || '')}
                        </h1>
                        <Button
                            outlined
                            minWidth={false}
                            name={'editAboutBtn'}
                            attrs={{onClick: () => props.onClose(false)}}
                            classes={'!p-0 !w-[36px] !h-[36px] !border-0 !bg-neutral-100 !text-shade-black'}
                        >
                            <CrossIcon />
                        </Button>
                    </Dialog.Title>
                    {props.children}
                </Dialog.Panel>
            </div>
        </Dialog>
    );
}

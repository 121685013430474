import { Outlet, useLocation } from "react-router-dom";
import { PageRoutes } from "@/utils/PageRoutes";
import JobsPageNavbarMenu from "@/js/modules/jobs/components/JobsPageNavbarMenu";
import RoleBasedNavbar from "@/js/components/RoleBasedNavbar/RoleBasedNavbar";
import blokPublicStore from "@/js/modules/bloks/store/BlokPublicStore";

interface IDashboardLayout { }

export default function DashboardLayout(props: IDashboardLayout) {
	blokPublicStore.initializeStore(blokPublicStore.state)

	const location = useLocation();

	const showJobsPageMenu = location.pathname === PageRoutes.DashboardBlokJobs || location.pathname === PageRoutes.DashboardRewardJobs;
	const isBlokPage = location.pathname === PageRoutes.BlokPublicPage.replace(':id', String(blokPublicStore.state.info.id)).replace(':tab?', '');

	return (
		<>
			<RoleBasedNavbar />
			<main className={`h-[calc(100vh-120px)] max-w-screen-2xl mx-auto ${isBlokPage ? "mt-0" : "mt-4"} print:mt-0`}>
				{showJobsPageMenu && <JobsPageNavbarMenu />}
				<Outlet />
				{/* <PlatformWarningModal /> */}
			</main>
		</>
	);
}

import BaseService from "@/js/core/BaseService";
import { BlokEndpoints } from '@/js/modules/bloks/services/BlokEndpoints';

export default class BlokPublicService extends BaseService {
  async fetchBlok(id: string) {
    return this.api().get(BlokEndpoints.FetchBlok.replace(':id', id));
  }
	async fetchBlokForServicePage(id: string) {
    return this.api().get(BlokEndpoints.FetchBlokForServicePage.replace(':id', id));
  }
  async fetchBlokAnalytics(id: string) {
    return this.api().get(BlokEndpoints.FetchBlokAnalytics.replace(':id', id));
  }
  async subscribeAsCompany(id: string) {
    return this.api().post(BlokEndpoints.FetchBlokCompanies.replace(':id', id));
  }
  async subscribeAsTalent(id: string) {
    return this.api().post(BlokEndpoints.FetchBlokTalents.replace(':id', id));
  }
  async uploadCoverPhoto(id: string, data: unknown) {
    return this.api().put(BlokEndpoints.UploadCoverPhoto.replace(':id', id), data);
  }
  async uploadAvatarPhoto(id: string, data: unknown) {
    return this.api().put(BlokEndpoints.UploadAvatarPhoto.replace(':id', id), data);
  }
}

export enum Icons {
    Blok = 'blok',
    Megaphone = 'megaphone',
    ArrowDown = 'arrow-down',
    BlokPublish = 'blok-publish',
    BlokSelect = 'blok-select',
    Price = 'price',
    Cork = 'cork',
    Pencil = 'pencil',
    Linkedin = 'linkedin',
    Github = 'github',
    Google = 'google',
    Close = 'close',
    ThreeDot = 'three-dot',
    InVisible = 'invisible',
    Visible = 'visible',
    Settings = 'settings',
    ArrowLeft = 'arrow-left',
    Instagram = 'instagram',
    Plus = 'plus',
    Check = 'check',
    CheckV2 = 'check-v2',
    Search = 'search',
    Spinner = 'spinner',
    Bell = 'bell',
    Payment = 'payment',
    SettingsV2 = 'settings-v2',
    Hamburger = 'hamburger',
    Logout = 'logout',
    Info = 'info',
    Building = 'building',
    CandidateZoom = 'candidate-zoom',
    User = 'user',
    Exlamation = 'exlamation',
    CursorArrow = 'cursor_arrow',
    Calendar = 'calendar',
}

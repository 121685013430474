import BaseStore from '@/js/core/BaseStore';
import { IBlokPublicStore } from '@/js/modules/bloks/store/BlokTypes';
import BlokPublicService from '@/js/modules/bloks/services/BlokPublicService';
import { RowStates } from '@/js/core/enums/RowStates';
import userStore from '@/js/modules/users/store/v2/UserStore';
import { AccountTypes } from '@/utils/Enums';
import adminStore from "@/js/modules/admin/store/AdminStore";
import storyStore from '@/js/modules/stories/store/StoryStore';
import companyStore from "@/js/modules/company/store/CompanyStore.ts";

const blokPublicStore = {
	info: {
		id: '',
		name: '',
		avatarUrl: '',
		coverPhotoUrl: '',
		description: '',
		isBlokOwnerVisible: false,
		isGlobal: false,
		isOwner: false,
		applicationType: '',
		locations: [],
		sectors: [],
		skills: [],
		expertises: [],
		outreach: '',
		socialAccounts: [],
		state: RowStates.Pending,
		user: {},
	},
	analytics: {
		companies: 0,
		jobPosts: 0,
		talents: 0,
		pendingCompanies: 0,
		pendingJobPosts: 0,
		pendingTalents: 0,
	},
	isTabLoading: false,
	company: {},
};

class BlokPublicStore extends BaseStore<IBlokPublicStore, BlokPublicService> {
	initializeStore(state?) {
		return this.useStore(state);
	}

	async fetchBlok(blokId: string) {
		this.state.isLoading = true;

		try {
			const [response, isAdmin] = await Promise.all([
				this.service.fetchBlok(blokId),
				adminStore.checkIsAdmin(),
				this.fetchBlokAnalytics(blokId),
				storyStore.fetchByEntityType(blokId, AccountTypes.BlokOwner),
			])

			if (userStore.isCompanyOwnerAccountType()) {
				this.state.company = await companyStore.fetchCompany();
			}

			this.setFormData(response.data.result);

			if (!response.data.result.isOwner && response.data.result.state !== RowStates.Approved && !isAdmin) {
				this.goToNotFound();
			}

			return response.data.result;
		} catch (error) {
			console.log(error);
			// this.goToNotFound();
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchBlokForPackageDetail(blokId: string) {
		this.state.isLoading = true;
		try {
			const [response] = await Promise.all([
				this.service.fetchBlokForServicePage(blokId)
			])
			this.setFormData(response.data.result);

			return response.data.result;
		} catch (error) {
			this.goToNotFound();
		} finally {
			this.state.isLoading = false;
		}
	}


	async fetchBlokAnalytics(id: string) {
		this.state.isTabLoading = true;

		try {
			const response = await this.service.fetchBlokAnalytics(id);

			this.setFormDataForAnalytics(response.data.result);
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isTabLoading = false;
		}
	}

	async subscribe(id: string, accountType: AccountTypes) {
		this.state.isLoading = true;

		try {
			if (accountType === AccountTypes.CompanyOwner) {
				await this.service.subscribeAsCompany(id);
				this.state.info.applicationType = RowStates.Pending
			}

			if (accountType === AccountTypes.Talent) {
				await this.service.subscribeAsTalent(id);
				this.fetchBlok(id);
			}
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isLoading = false;
		}
	}

	getSkillAndSectors() {
		let result = [];

		result = result.concat(this.state.info.skills)
		result = result.concat(this.state.info.sectors)

		return result
	}

	getSkills() {
		return this.state.info.skills
	}

	isCompanyOwnerAccountType() {
		return userStore.isCompanyOwnerAccountType();
	}

	isVisitor() {
		return userStore.isVisitor();
	}

	isTalentAccountType() {
		return userStore.isTalentAccountType();
	}

	isBlokOwnerAccountType() {
		return userStore.isBlokOwnerAccountType();
	}

	isOwner() {
		return this.state?.info?.isOwner && this.isBlokOwnerAccountType();
	}

	async isAdmin() {
		return await adminStore.checkIsAdmin();
	}


	async uploadCoverPhoto(id: string) {
		const response = await this.service.fetchBlok(id);

		await this.service.uploadCoverPhoto(id, {
			...response.data.result,
			coverPhotoUrl: this.state.info.coverPhotoUrl
		})
	}

	setCoverPhotoUrl(url: string) {
		this.state.info.coverPhotoUrl = url;
	}

	async updateAvatarImage(newAvatarUrl: string) {
		this.state.info.avatarUrl = newAvatarUrl

		await this.service.uploadAvatarPhoto(this.state.info.id, {
			...this.state.info,
			avatarUrl: newAvatarUrl
		})
	}

	setFormData(data) {
		this.state.info = {
			id: data.id,
			name: data.name,
			avatarUrl: data.avatarUrl,
			coverPhotoUrl: data.coverPhotoUrl,
			description: data.description,
			isBlokOwnerVisible: data.isBlokOwnerVisible,
			isGlobal: data.isGlobal,
			isOwner: data.isOwner,
			applicationType: data.applicationType,
			locations: data.locations,
			sectors: data.sectors,
			skills: data.skills,
			socialAccounts: data.socialAccounts,
			regions: data?.regions,
			state: data.state,
			user: data.user,
			outreach: data.outreach,
			expertises: data.expertises
		}
	}

	setFormDataForAnalytics(data) {
		this.state.analytics = {
			companies: data.companies,
			jobPosts: data.jobPosts,
			talents: data.talents,
			pendingCompanies: data.pendingCompanies,
			pendingJobPosts: data.pendingJobPosts,
			pendingTalents: data.pendingTalents,
		}
	}
}

export default new BlokPublicStore(blokPublicStore, BlokPublicService) as BlokPublicStore;

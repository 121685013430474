import React from "react";
import { __ } from "@/utils/Trans";
import { BaseComponentProps } from "@/utils/BaseComponentProps";
import Badge from "../Badge/Badge";
import { UISize, UIState } from "@/utils/Enums";

interface ITextAreaProps extends BaseComponentProps {
  label?: string;
  placeholder?: string;
  rowCount?: number;
  maxWordCount?: number;
  attrs?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
  message?: string;
  error?: { [key: string]: string; };
  isResizeable?: boolean;
  hideCounter?: boolean;
  isDisabled?: boolean;
  isOptional?: boolean;
}
let isOptional = false;

const TextArea = (props: ITextAreaProps) => {
  const { attrs, label, placeholder, maxWordCount = 1000, rowCount = 3, isResizeable = false, hideCounter = false } = props;
  isOptional = props.isOptional || false;

  const [wordCount, setWordCount] = React.useState(attrs?.value ? String(attrs.value).length : 0);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setWordCount(e.target.value.length);
    if (attrs?.onChange) attrs.onChange(e);
  };

  return (
    <div className={"relative flex flex-col gap-2"}>
      {!props.label || (
        <span className={"gap-10 text-shade-black text-body-semibold-b5 dark:text-gray-400"}>
          {label}
          <span className="pl-[10px]">
            {isOptional && (
              <Badge size={UISize.Sm} type={UIState.Success}>
                {__('Optional')}
              </Badge>
            )}
          </span>
        </span>
      )}
      <textarea
        {...attrs}
        rows={rowCount}
        maxLength={maxWordCount}
        placeholder={placeholder}
        disabled={props.isDisabled}
        onChange={handleInputChange}
        className={`${props.error?.[props?.attrs?.name!] ? "border-error-400" : "border-shade-stroke"} ${props.isDisabled ? 'cursor-not-allowed bg-[#f2f2f2]' : ''} dark:bg-transparent dark:text-gray-300 dark:border-gray-500 w-full placeholder:text-neutral-500 placeholder:text-label-regular-l5 focus:ring-0 placeholder:leading-5 border border-shade-stroke ${isResizeable ? "" : "resize-none"} rounded-lg transition duration-150 ease-in-out focus:border-primary-500 hover:border-primary-50 shadow-none outline-none`}
      />
      <div className={"flex justify-between items-center"}>
        <div>
          {props.error?.[props?.attrs?.name!] && (
            <p className={"text-sm font-medium"}>
              <span className={"text-error-400"}>
                {__(props.error[props?.attrs?.name!])}
              </span>
            </p>
          )}
        </div>
        {!hideCounter && (
          <p className={"text-base text-right leading-5 text-neutral-500"}>
            {wordCount}/{maxWordCount}
          </p>
        )}
      </div>
    </div>
  );
}

export default TextArea;

import Image from "@/js/components/Image/Image";
import ModalV2 from "@/js/components/ModalV2/ModalV2";
import { Images } from "@/utils/Images";
import { __ } from "@/utils/Trans";

interface IDemoRequestSentModal {
	open: boolean;
	onClose: () => void;
}

export default function DemoRequestSentModal({ open, onClose }: IDemoRequestSentModal) {
	return (
		<ModalV2 open={open} onClose={onClose}>
			<div className="flex flex-col justify-center items-center pb-12">
				<Image
					height={200}
					width={360}
					src={Images.DemoImage}
					className="object-cover"
				/>
				<h3 className="mb-2 mt-3 text-shade-black text-header-bold-h8 text-center">
					{__("DemoRequestSent.Title")}
				</h3>
				<p className="text-neutral-500 text-body-regular-b5 text-center">
					{__("DemoRequestSent.Description")}
				</p>
			</div>
		</ModalV2>
	)
}
import ChevronLeftIcon from '@/assets/icons/ChevronLeftIcon';
import ChevronRightIcon from '@/assets/icons/ChevronRightIcon';
import { __ } from '@/utils/Trans';
import React from 'react';
import Pagination from 'react-js-pagination';

interface IBaseTable extends React.PropsWithChildren {
	children?: React.ReactNode[] | React.ReactNode;
	classes?: string;
	emptyText?: string;
	hasPagination?: boolean;
	meta?: {
		paginate: (pageNumber: number) => void;
		currentPage: number;
		itemCount: number;
		itemsPerPage: number;
		totalItems: number;
		totalPages: number;
	};
	columns: {
		name: string;
		classes?: string;
		infoTooltip?: JSX.Element;
		render?: (new () => React.Component) | any;
	}[];
}

const BaseTable = (props: IBaseTable) => {
	const { hasPagination = false, emptyText = __('BaseTable.EmptyState') } = props;

	let children = Array.isArray(props.children)
		? props.children
		: props.children
		? [props.children]
		: [];

	if (children[0] && Array.isArray(children[0]) && !children[0].length) {
		children = [];
	}

	const shouldShowPagination = hasPagination && props.meta && props.meta.totalPages > 1;

	return (
		<>
			<div className={`mt-8 flow-root print:mt-0 ${props.classes}`}>
				<div className="-mx-4 -my-2 sm:overflow-x-visible sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
						<table className="min-w-full divide-y divide-gray-50 dark:divide-gray-500 space-y-3 overflow-scroll">
							<thead className={'hidden md:table-header-group print:table-header-group'}>
								<tr>
									{props.columns.map((column, index) => (
										<th
											scope="col"
											key={index}
											className={`dark:text-gray-400 text-gray-500 py-3.5 pl-4 pr-3 text-left text-sm font-normal ${
												column.classes || ''
											}`}
										>
											{column.render ? <column.render /> : __(column.name)}{' '}
											{column?.infoTooltip ? column?.infoTooltip : null}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{children.length ? (
									children.map((child) => child)
								) : (
									<tr>
										<td
											colSpan={props.columns.length}
											className={'dark:text-gray-400 text-center text-sm text-gray-500 p-5'}
										>
											{emptyText}
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			{shouldShowPagination && (
				<div
					className={
						'flex items-center justify-center md:justify-end bg-white dark:bg-transparent py-3'
					}
				>
					<Pagination
						itemsCountPerPage={props.meta?.itemsPerPage}
						activePage={props.meta?.currentPage as number}
						totalItemsCount={props.meta?.totalItems as number}
						onChange={(pageNumber) => props?.meta?.paginate(pageNumber)}
						prevPageText={<ChevronLeftIcon />}
						nextPageText={<ChevronRightIcon />}
						firstPageText={__('AdminEditTranslationsPage.First')}
						lastPageText={__('AdminEditTranslationsPage.Last')}
						innerClass={'flex items-center justify-center gap-3 md:gap-6 py-3 w-full'}
						linkClass={
							'relative inline-flex items-center px-2 md:px-4 py-2 text-sm font-medium dark:text-white text-gray-700 dark:hover:bg-gray-400 hover:bg-gray-50 dark:hover:rounded'
						}
						activeLinkClass={
							'relative z-10 inline-flex items-center bg-blue-500 rounded-md hover:bg-indigo-600 px-4 py-2 text-sm font-semibold text-white'
						}
						linkClassLast={
							'relative inline-flex items-center px-2 md:px-4 py-2 text-sm text-gray-900 rounded-r-md hover:bg-gray-50'
						}
						linkClassFirst={
							'relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 hover:bg-gray-50'
						}
						linkClassPrev={
							'relative inline-flex items-center px-2 py-2 text-gray-400 hover:bg-gray-50'
						}
						linkClassNext={
							'relative inline-flex items-center px-2 py-2 text-gray-400 hover:bg-gray-50'
						}
					/>
				</div>
			)}
		</>
	);
};

interface IRow extends React.PropsWithChildren {
	classes?: string;
	onClick?: React.MouseEventHandler<HTMLTableRowElement>;
}

const Row = (props: IRow) => {
	const children = Array.isArray(props.children) ? props.children : [props.children];

	return (
		<tr
			onClick={props.onClick}
			className={`border-b border-gray-50 p-3 rounded-2xl flex flex-col print:table-row lg:table-row dark:border-b-gray-500 ${props.classes}`}
		>
			{children.map((child) => child)}
		</tr>
	);
};

interface ICell extends React.PropsWithChildren {
	children?: React.ReactNode;
	classes?: string;
	colSpan?: number;
	rowSpan?: number;
}

const Cell = (props: ICell) => {
	return (
		<td
			rowSpan={props.rowSpan}
			colSpan={props.colSpan}
			className={`whitespace-nowrap px-3 py-5 text-sm text-gray-500 ${props.classes}`}
		>
			{props.children}
		</td>
	);
};

Row.Cell = Cell;
BaseTable.Row = Row;
BaseTable.Row.Cell = Cell;

export default BaseTable;

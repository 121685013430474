import React from "react";
import LandingLayout from '@/js/layouts/landing/LandingLayout';
import DashboardLayout from '@/js/layouts/dashboard/DashboardLayout';
import userStore from '@/js/modules/users/store/v2/UserStore';
import { useQuery } from "@tanstack/react-query";

export default function CommonLayout() {
	const [isAuth, setIsAuth] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(true);

	useQuery({
		queryKey: ['me'],
		queryFn: async () => {
			try {
				setIsLoading(true)

				const result = await userStore.fetchMe()

				if (result) {
					setIsAuth(true);
				} else {
					setIsAuth(false);
				}

			} catch (error) {
				setIsAuth(false);
			} finally {
				setIsLoading(false)
				return true;
			}
		},
	})

	return isLoading ? <></> : getLayout(isAuth);
}

function getLayout(isAuth: boolean) {
	return isAuth ? <DashboardLayout /> : <LandingLayout hideFooter />
}

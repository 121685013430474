import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import blokStore from "@/js/modules/bloks/store/BlokStore";
import { isJoinBlokFlow } from "../utils/OnboardingHelper";

const OnboardingBlokInfo = () => {
	blokStore.initializeStore()

	const [showBlokInfo, setShowBlokInfo] = React.useState(false)
	const [searchParams] = useSearchParams();
	const redirect = String(searchParams.get('redirect'))

	const { data : blokData } = useQuery({
		queryKey: ['redirect', redirect],
		queryFn: async () => {
			if(redirect.includes("bloks")){
				const blokId = redirect.replace("/bloks/", "").replace("/","")
				const data =  blokId && blokId !== 'null' ? blokStore.fetchBlokInfo(blokId) : undefined
				return data;
			}
			return undefined;
		},
	})

	React.useEffect(() => {
		(async () => {
			if(isJoinBlokFlow(searchParams)){
				setShowBlokInfo(true)
			}
		})()
	// eslint-disable-next-line
	}, []);

	return (
		<>
			{showBlokInfo && blokData &&
				<div className="flex items-center gap-4 md:gap-[10px] py-4 px-10 border-b-2 border-b-neutral-50">
					<img
						src={blokData?.data.result.avatarUrl}
						className='inline-block h-10 w-10 rounded-[10px] ring-1 ring-neutral-200'
					/>
					<div className="flex flex-col md:flex-row md:gap-6 justify-start md:items-center">
						<p className="text-body-bold-b5 md:text-body-bold-b4">{blokData?.data.result.name}</p>
						{blokData?.data.result.user &&
						<p className="text-body-regular-b6 md:text-body-regular-b6 md:text-neutral-500">{blokData?.data.result.user.firstName} {blokData?.data.result.user.lastName}</p>
					}
					</div>
				</div>
			}
		</>
	)
}

export default OnboardingBlokInfo;

import React from 'react';
import { Disclosure } from '@headlessui/react';
import XMarkIcon from '@/assets/icons/XMarkIcon';
import Bars3Icon from '@/assets/icons/Bars3Icon';
import DWSLogo from '@/js/components/DWS/DWSLogo';
import { PageRoutes } from '@/utils/PageRoutes';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Button from '@/js/components/Buttons/Button';
import LandingNavbarLangSelector from './LandingNavbarLangSelector';
import { __ } from '@/utils/Trans';
import { useLogout } from '@/js/hooks/useLogout/useLogout';
import userStore from '../../users/store/v2/UserStore';
import setRedirectForAuth from '@/js/helpers/setRedirectForAuth';
import { useScrollBlock } from '@/js/hooks/useScrollBlock/useScrollBlock';
import { useScrollPosition } from '@/js/hooks/useScrollPosition/useScrollPosition';
import { useIsomorphicLayoutEffect } from '@/js/hooks/useIsomorphicLayoutEffect/useIsomorphicLayoutEffect';

const links = [
	{ to: PageRoutes.DiscoverBloksPage, translationKey: 'TopBar.Bloks' },
	// { to: PageRoutes.SubscriptionInfoPage, translationKey: 'LandingPage.Footer.Pricing' },
	// { to: PageRoutes.LandingForCommunities, translationKey: 'LandingNavbar.Communities' },
	// { to: PageRoutes.LandingPageForTalents, translationKey: 'LandingNavbar.Talents' },
	// { to: PageRoutes.LandingForCompanies, translationKey: 'LandingNavbar.Companies' },
];

interface IPublicNavbar {
	hideAll?: boolean;
}

const authPages = [
	PageRoutes.SignIn,
	PageRoutes.SignUp,
	PageRoutes.WelcomePage,
	PageRoutes.ResetPassword,
	PageRoutes.ForgotPassword,
];

const colorChangePages = [
	PageRoutes.ForgotPassword,
	PageRoutes.ResetPassword,
	PageRoutes.SignUp,
	PageRoutes.SignUpMailActivation,
];

export default function PublicNavbar(props: IPublicNavbar) {
	const { hideAll = false } = props;

	const [stickyClasses, setStickyClasses] = React.useState('');
	const [navClasses, setNavClasses] = React.useState('');

	const location = useLocation();
	const navigate = useNavigate();
	const handleLogout = useLogout();
	const scrollPosition = useScrollPosition();
	const [blockScroll, allowScroll] = useScrollBlock();

	const isLandingForCommunities = location.pathname === PageRoutes.LandingForCommunities;

	const handleLoginClick = async () => {
		window.scrollTo(0, 0);
		allowScroll();

		const result = await userStore.fetchMe();

		if (result && !result?.emailVerifiedAt) {
			return handleLogout();
		}

		const redirectUrl = setRedirectForAuth();
		const isBlogPages = window.location.href.includes('blog');

		if (isBlogPages) {
			window.location.href = `${String(process.env.VITE_FE_URL)}${PageRoutes.WelcomePage}`;
		} else {
			navigate({
				pathname: PageRoutes.WelcomePage,
				search: redirectUrl ? `?redirect=${redirectUrl}` : '',
			});
		}
	};

	const handleCompanyBtnClick = (closeMenu) => {
		window.scrollTo(0, 0);
		navigate(PageRoutes.LandingForCompanies);
		allowScroll();
		closeMenu();
	};

	const handleCommunityBtnClick = (closeMenu) => {
		window.scrollTo(0, 0);
		navigate(PageRoutes.LandingForCommunities);
		allowScroll();
		closeMenu();
	};

	useIsomorphicLayoutEffect(() => {
		if (scrollPosition !== 0 && !authPages.includes(location.pathname as PageRoutes)) {
			setStickyClasses('!mt-0 shadow fixed top-0');
			setNavClasses('h-16');
		} else {
			setStickyClasses('');
			setNavClasses('');
		}
	}, [scrollPosition]);

	return (
		<Disclosure
			as={'nav'}
			className={`pt-0 w-full bg-white z-50 transition duration-300 ease-in-out lg:pt-5 ${stickyClasses} ${
				colorChangePages.includes(location.pathname as PageRoutes) && 'xl:!bg-shade-background'
			}`}
		>
			{({ open, close }) => (
				<>
					<div className="mx-auto p-4 sm:px-6 lg:px-8 lg:pt-0">
						<div className={`flex items-center justify-between ${navClasses}`}>
							<div className="flex items-center">
								<div className="flex-shrink-0">
									<DWSLogo forceRedirect useLong />
								</div>
							</div>
							{hideAll ? (
								<LandingNavbarLangSelector />
							) : (
								<div className="hidden lg:ml-6 lg:block">
									<div className="flex items-center">
										<div className="hidden lg:ml-6 lg:block">
											<div className="flex items-center gap-6">
												{links.map((link) => (
													<NavLink
														key={link.to}
														to={link.to}
														onClick={() => window.scrollTo(0, 0)}
														className={({ isActive }) => {
															return `text-body-semibold-b5 text-primary-700 ${
																isActive && '!text-primary-500'
															}`;
														}}
													>
														{__(link.translationKey)}
													</NavLink>
												))}
												<LandingNavbarLangSelector />
												<Button
													outlined
													name={'specialBtn'}
													classes={'!py-[9.5px] ml-4 min-w-[214px]'}
													attrs={{
														onClick: isLandingForCommunities
															? () => handleCompanyBtnClick(close)
															: () => handleCommunityBtnClick(close),
													}}
												>
													<span>
														{isLandingForCommunities
															? __('LandingPage.Footer.ForCompanies')
															: __('LandingPage.Footer.ForCommunities')}
													</span>
												</Button>
												<Button
													name={'loginBtn'}
													classes={'!py-[9.5px] ml-2 w-[214px]'}
													attrs={{ onClick: handleLoginClick }}
												>
													<span>{__('LandingNavbar.Login')}</span>
												</Button>
											</div>
										</div>
									</div>
								</div>
							)}
							{!hideAll && (
								<div onClick={open ? allowScroll : blockScroll} className="-mr-2 flex lg:hidden">
									{/* Mobile menu button */}
									<Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400  hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
										<span className="absolute -inset-0.5" />
										<span className="sr-only">Open main menu</span>
										{open ? <XMarkIcon aria-hidden="true" /> : <Bars3Icon aria-hidden="true" />}
									</Disclosure.Button>
								</div>
							)}
						</div>
					</div>
					<Disclosure.Panel className={`lg:hidden h-[calc(100svh-80px)]`}>
						<div className="space-y-1 pt-20 px-5 pb-8 h-full">
							<div className="flex flex-col justify-between h-full items-center">
								<div className="flex flex-col justify-center items-center gap-10">
									{links.map((link) => (
										<span
											key={link.to}
											onClick={() => {
												window.scrollTo(0, 0);
												allowScroll();
											}}
										>
											<Disclosure.Button
												as={NavLink}
												to={link.to}
												className={({ isActive }) => {
													return `text-body-semibold-b5 text-primary-700 ${
														isActive && '!text-primary-500'
													}`;
												}}
											>
												{__(link.translationKey)}
											</Disclosure.Button>
										</span>
									))}
									<LandingNavbarLangSelector />
								</div>
								<div className="flex flex-col items-center">
									<Button
										outlined
										name={'specialBtn'}
										classes={'mb-5'}
										attrs={{
											onClick: isLandingForCommunities
												? () => handleCompanyBtnClick(close)
												: () => handleCommunityBtnClick(close),
										}}
									>
										{isLandingForCommunities
											? __('LandingPage.Footer.ForCompanies')
											: __('LandingPage.Footer.ForCommunities')}
									</Button>
									<Button name={'loginBtn'} attrs={{ onClick: handleLoginClick }}>
										{__('LandingNavbar.Login')}
									</Button>
								</div>
							</div>
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	);
}

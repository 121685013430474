import TextArea from '@/js/components/TextArea/TextArea';
import TextBox from '@/js/components/TextBox/TextBox';
import { Outlet } from 'react-router-dom';
import Button from '@/js/components/Buttons/Button';
import { InputType } from '@/js/components/Input/InputType';
import { __ } from '@/utils/Trans';
import axios from 'axios';
import React, { useState } from 'react';
import { AccountTypes, LandingSubmitTypes} from '@/utils/Enums';
import { LandingEndPoints } from '@/js/modules/landing/services/EndPoints';
import { errorHandler } from '@/js/helpers/errorHandler';
import CookieConsentBanner from '@/js/modules/landing/components/CookieConsentBanner';
import LandingFooter from '@/js/modules/landing/components/LandingFooter';
import PublicNavbar from '@/js/modules/landing/components/PublicNavbar';
import { PhoneInput } from 'react-international-phone';
import DemoRequestSentModal from '@/js/modules/landing/components/DemoRequestSentModal';

const initialValues = {
	fullName: '',
	accountType: '',
	email: '',
	phoneNumber: '',
	message: '',
	firstname: '',
	surname: '',
};

const DemoLayout = () => {
	const [values, setValues] = React.useState(initialValues);
	const [errors, setErrors] = React.useState({});
	const [isSubmitting, setIsSubmitting] = React.useState(false);
	const [accountType, setAccountType] = useState<string | null>(null);
	const [phone, setPhoneNumber] = useState<string | null>(null);
	const [isRequestSentOpen, setIsRequestSentOpen] = useState(false);

	const handleAccountTypeClick = (accountId: string) => {
		setAccountType(accountId === accountType ? null : accountId);
		setValues({ ...values, accountType: accountId });
	};
	const handlePhoneInput = (PhoneInput: string) => {
		setPhoneNumber(phone === phone ? null : PhoneInput);
		setValues({ ...values, phoneNumber: PhoneInput });
	};

	const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		values.fullName = values.firstname + values.surname;
		setIsSubmitting(true);
		setErrors({});

		const payload = {
			...values,
			type: LandingSubmitTypes.Demo,
		};

		try {
			const response = await axios.post(LandingEndPoints.Contacts, payload);

			if (response.status === 201) {
				setIsRequestSentOpen(true);
			}
		} catch (error) {
			setErrors(errorHandler(error)!);
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;

		if (errors[name]) {
			setErrors({ ...errors, [name]: '' });
			return;
		}

		if (name === 'firstname' || name === 'surname') {
			initialValues.fullName = initialValues.firstname + initialValues.surname;
		}
		setValues({ ...values, [name]: value });
	};

	return (
		<>
			<CookieConsentBanner />
			<PublicNavbar />
			<form className={'flex flex-col lg:flex-row lg:pb-20'} onSubmit={handleFormSubmit}>
				<div className="lg:w-[52%] md:w-1/2 lg:pr-12 lg:pt-12 lg:pb-60 px-4 py-16">
					<Outlet />
				</div>
				<div className="lg:w-[48%] md:w-1/2 px-8 lg:pr-28 lg:pl-12 pt-8 lg:pt-24 pb-8 flex flex-col gap-6 sm:col-span-4">
					<div className={'flex flex-col gap-8 sm:gap-5 sm:flex-row'}>
						<div className={'w-full'}>
							<TextBox name={'firstname'}>
								<TextBox.Label>{__('LandingCommon.ContactLayout.Name')}</TextBox.Label>
								<TextBox.Input
									name={'firstname'}
									error={errors}
									attr={{
										value: values.firstname,
										placeholder: __('LandingCommon.ContactLayout.NamePlaceholder'),
										onChange: handleInputChange,
										name: 'firstname',
										type: InputType.Text,
									}}
								/>
							</TextBox>
						</div>
						<div className={'w-full'}>
							<TextBox name={'surname'}>
								<TextBox.Label>{__('LandingCommon.ContactLayout.Surname')}</TextBox.Label>
								<TextBox.Input
									name={'surname'}
									error={errors}
									attr={{
										value: values.surname,
										placeholder: __('LandingCommon.ContactLayout.SurnamePlaceholder'),
										onChange: handleInputChange,
										name: 'surname',
										type: InputType.Text,
									}}
								/>
							</TextBox>
						</div>
					</div>
					<div className={'flex flex-col gap-8 sm:gap-5 sm:flex-row '}>
						<div className={'w-full'}>
							<TextBox name={'email'}>
								<TextBox.Label>{__('LandingCommon.ContactLayout.Email')}</TextBox.Label>
								<TextBox.Input
									name={'email'}
									error={errors}
									attr={{
										value: values.email,
										placeholder: __('LandingCommon.ContactLayout.EmailPlaceholder'),
										onChange: handleInputChange,
										name: 'email',
										type: InputType.Email,
									}}
								/>
							</TextBox>
						</div>
						<div className={'w-full'}>
							<TextBox.Label>{__('LandingCommon.ContactLayout.PhoneNumber')}</TextBox.Label>
							<PhoneInput
								defaultCountry="tr"
								name={'phoneNumber'}
								placeholder={__('LandingCommon.ContactLayout.PhoneNumber')}
								value={values.phoneNumber}
								onChange={(value) => handlePhoneInput(value)}
								className={
									'!rounded-lg p-3 mt-1 h-[50px] flex items-center transition duration-150 ease-in-out outline-none input-wrapper bg-white'
								}
							/>
						</div>
					</div>
					<div>
						<TextBox.Label>{__('DemoRequest.UserType')}</TextBox.Label>
						<div className={'flex flex-col sm:flex-row gap-x-2.5 mt-1'}>
							<div
								className={`${
									accountType === AccountTypes.CompanyOwner
										? '!bg-[#E8DBFF] text-primary-500 border-primary-500'
										: ''
								} border-lavender-web-2 text-gray bg-white mt-1 flex items-center justify-center text-base hover:text-primary-500 py-3 px-8 border hover:border-primary-500 rounded-lg hover:bg-[#E8DBFF] cursor-pointer w-full sm:w-1/2`}
								onClick={() => handleAccountTypeClick(AccountTypes.CompanyOwner)}
							>
								{__('AccountTypes.CompanyOwner')}
							</div>
							<div
								className={`${
									accountType === AccountTypes.BlokOwner
										? '!bg-[#E8DBFF] text-primary-500 border-primary-500'
										: ''
								} border-lavender-web-2 text-gray bg-white mt-1 flex items-center justify-center text-base hover:text-primary-500 py-3 px-8 border hover:border-primary-500 rounded-lg hover:bg-[#E8DBFF] cursor-pointer w-full sm:w-1/2`}
								onClick={() => handleAccountTypeClick(AccountTypes.BlokOwner)}
							>
								{__('AccountTypes.BlokOwner')}
							</div>
						</div>
					</div>
					<div className={'relative ContactTextArea'}>
						<TextBox.Label>{__('LandingCommon.ContactLayout.Message')}</TextBox.Label>
						<TextArea
							error={errors}
							attrs={{
								value: values.message,
								name: 'message',
								onChange: handleInputChange,
							}}
							name={'message'}
							placeholder={__('DemoRequest.MessagePlaceholder')}
						/>
					</div>
					<Button isLoading={isSubmitting} name={'submit'} classes={'lg:w-[240px] lg3:w-[240px]'}>
						{__('LandingCommon.ContactLayout.Send')}
					</Button>
				</div>
			</form>
			<LandingFooter />
			<DemoRequestSentModal open={isRequestSentOpen} onClose={() => setIsRequestSentOpen(false)} />
		</>
	);
};

export default DemoLayout;

const TwitterXIcon = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.45 22.27C17.2489 22.27 21.95 17.569 21.95 11.77C21.95 5.97103 17.2489 1.27002 11.45 1.27002C5.65096 1.27002 0.949951 5.97103 0.949951 11.77C0.949951 17.569 5.65096 22.27 11.45 22.27Z" fill="black" stroke="white" strokeMiterlimit="10"/>
      <path d="M4.98195 5.68663L10.0008 12.3972L4.95032 17.8533H6.08707L10.5089 13.0763L14.0815 17.8533H17.9496L12.6482 10.7653L17.3493 5.68663H16.2125L12.1404 10.086L8.85011 5.68663H4.98195ZM6.6536 6.52387H8.43061L16.2777 fill-rule17.016H14.5007L6.6536 6.52387Z" fill="white"/>
    </svg>
  )
}

export default TwitterXIcon;
